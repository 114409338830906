import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "tf-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent implements OnInit {
  @Input() title!: string;
  @Input() additionalStyle?: string;
  @Input() additionalWrapperStyle?: string;

  constructor() {}

  ngOnInit(): void {}
}
