<div class="relative">
  <input
    class="peer absolute top-[35%] left-[10px]"
    type="radio"
    [name]="group"
    [id]="elementId"
    [checked]="controlValue === value"
  />
  <label [for]="elementId"
         (click)="updateControl()"
         class="flex items-center ps-8 justify-center rounded-lg border peer-checked:bg-secondary-100 min-w-[100px] {{RadioSize[size]}} {{additionalClasses}} cursor-pointer">
    <i class="{{icon}} text-md me-2" *ngIf="icon"></i>
    {{ label }}
  </label>
</div>
