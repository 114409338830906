import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { THRESHOLD_AMOUNT } from "../../core/constants/storageKeys.constant";
import { IChannelStatus } from "../../core/interfaces/channel.interface";
import { IdentityState } from "../../core/store/identities/identities.state";
import { ShopifyStoreState } from "../../core/store/user-store/user-store.state";
import { IWallet } from "../../core/interfaces/credit.interface";
import { AlertToastMessage } from "../../core/constants/toast-message.constant";

@Component({
  selector: "tf-channel-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  @Select(IdentityState.getChannelStatus)
  channelStatus$: Observable<IChannelStatus>;

  @Select(ShopifyStoreState.getCurrentWallet)
  currentWallet$: Observable<IWallet>;

  @Output() getStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blockBasedOnCredit: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  channelStatus: boolean = false;
  creditStatus: boolean = false;
  showNonVerifiedText: boolean = false;
  blockOnCreditStatus: boolean = false;
  @Input() type: "CREDIT_STATUS" | "CHANNEL_STATUS" | string;
  @Input() keyNames: Array<string> = [];
  @Input() message: string;
  @Input() toastType: string = "warning";
  @Input() link:
    | { href: string; text: string; hrefVerify?: string; textVerify?: string }
    | undefined;
  @Input() dismissible: boolean = true;

  ngOnInit(): void {
    const trigger = {
      CHANNEL_STATUS: this._getChannelStatus,
      CREDIT_STATUS: this._checkCreditBalance,
    };

    trigger[this.type].bind(this).call();
  }

  _getChannelStatus() {
    this.channelStatus$.subscribe((res: IChannelStatus) => {
      const config = JSON.parse(JSON.stringify(res));
      if (config.isEmailEnabled) {
        this.showNonVerifiedText = !config.isEmailVerified;
        config.isEmailEnabled = config.isEmailVerified;
      }

      let status = config[this.keyNames[0]];
      this.keyNames.forEach((key, index) => {
        if (index > 0) {
          status = status || config[key];
        }
      });
      this.channelStatus = status;
      this.getStatus.emit(this.channelStatus);
    });
  }

  _checkCreditBalance() {
    this.currentWallet$.subscribe((res: IWallet) => {
      this.creditStatus =
        res?.balance > THRESHOLD_AMOUNT ||
        res?.subscriptionBalance > THRESHOLD_AMOUNT;
      if (res?.balance <= 0 && res?.subscriptionBalance <= 0) {
        this.blockOnCreditStatus = true;
        this.blockBasedOnCredit.emit(true);
      } else {
        this.blockBasedOnCredit.emit(false);
      }
    });
  }

  onClosed() {
    if (this.type === "CHANNEL_STATUS") {
      this.channelStatus = true;
      this.creditStatus = false;
    } else {
      this.creditStatus = true;
      this.channelStatus = false;
    }
  }

  protected readonly alertToastMessages = AlertToastMessage;
}
