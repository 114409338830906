<div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
  <div class="flex items-center justify-between pt-3 px-4">
    <h5 class="text-lg font-semibold text-gray-800">Preview</h5>
    <span
      class="p-2 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
      (click)="close()"
    >
      <i class="bi-x-lg scale-125"></i>
    </span>
  </div>
  <div class="flex flex-col items-center justify-center h-full">
    <img
      [src]="file?.url"
      [alt]="file?.fileName"
      class="mx-auto"
      *ngIf="file?.type === 'image' || file?.type === ''"
      style="height: 80vh; max-width: 90%; object-fit: contain"
    />
    <video
      [src]="file?.url"
      autoplay
      controls
      width="100%"
      *ngIf="file?.type === 'video'"
    ></video>
  </div>
</div>
