import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef,
} from "@angular/core";
import { TfModalComponent } from "../../components/core/tf-modal/tf-modal.component";
import { Options } from "../interfaces/core-elements.interface";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  newModalComponent!: ComponentRef<TfModalComponent>;
  options!: Options | undefined;
  config$: Subject<any>;

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {}

  open(
    vcrOrComponent: ViewContainerRef,
    content: TemplateRef<Element>,
    options?: Options
  ): ComponentRef<any>;

  open<C>(vcrOrComponent: Type<C>, options?: Options): ComponentRef<any>;

  open<C>(
    vcrOrComponent: ViewContainerRef | Type<C>,
    param2?: TemplateRef<Element> | Options,
    options?: Options
  ): ComponentRef<any> {
    let componentRef: ComponentRef<unknown> = null;
    if (vcrOrComponent instanceof ViewContainerRef) {
      this.openWithTemplate(vcrOrComponent, param2 as TemplateRef<Element>);
      this.options = options;
    } else {
      componentRef = this.openWithComponent(vcrOrComponent);
      this.options = param2 as Options | undefined;
    }
    this.config$ = new Subject<any>();
    return componentRef;
  }

  private openWithTemplate(
    vcr: ViewContainerRef,
    content: TemplateRef<Element>
  ) {
    vcr.clear();

    const innerContent = vcr.createEmbeddedView(content);

    this.newModalComponent = vcr.createComponent(TfModalComponent, {
      injector: this.injector,
      projectableNodes: [innerContent.rootNodes],
    });
  }

  private openWithComponent(component: Type<unknown>) {
    const newComponent: ComponentRef<unknown> = createComponent(component, {
      environmentInjector: this.injector,
    });

    this.newModalComponent = createComponent(TfModalComponent, {
      environmentInjector: this.injector,
      projectableNodes: [[newComponent.location.nativeElement]],
    });

    document.body.appendChild(this.newModalComponent.location.nativeElement);

    // Attach views to the changeDetection cycle
    this.appRef.attachView(newComponent.hostView);
    this.appRef.attachView(this.newModalComponent.hostView);
    return newComponent;
  }

  result(): Promise<any> {
    return this.config$.asObservable().toPromise();
  }

  close(result?: any) {
    console.log("result on modal service", result);
    if (result) {
      this.config$.next(result);
    }
    this.newModalComponent.instance.close();
    this.config$.next(result);
    this.config$.complete();
  }
}
