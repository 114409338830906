import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TfButtonComponent } from "../../components/core/tf-button/tf-button.component";
import { TfInputComponent } from "../../components/core/tf-input/tf-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TfCheckboxComponent } from "../../components/core/tf-checkbox/tf-checkbox.component";
import { CompactTabComponent } from "../../components/core/compact-tab/compact-tab.component";
import { TfBreadcrumbComponent } from "../../components/core/tf-breadcrumb/tf-breadcrumb.component";
import { AccordionTitleDirective } from "../../components/core/tf-accordion/accordion-title.directive";
import { AccordionHeaderDirective } from "../../components/core/tf-accordion/accordion-header.directive";
import { AccordionItemDirective } from "../../components/core/tf-accordion/accordion-item.directive";
import { AccordionContentDirective } from "../../components/core/tf-accordion/accordion-content.directive";
import { TfAccordionComponent } from "../../components/core/tf-accordion/tf-accordion.component";
import { TfBadgeComponent } from "../../components/core/tf-badge/tf-badge.component";
import { TfRadioComponent } from "../../components/core/tf-radio/tf-radio.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { TfDropdownDirective } from "../../components/core/directives/tf-dropdown/tf-dropdown.directive";
import { TfCalendarComponent } from "../../components/core/tf-calendar/tf-calendar.component";
import { TfTooltipDirective } from "../../components/core/tf-tooltip/tooltip.directive";
import { TfTimePickerComponent } from "../../components/core/tf-time-picker/tf-time-picker.component";

@NgModule({
  declarations: [
    TfButtonComponent,
    TfInputComponent,
    TfCheckboxComponent,
    CompactTabComponent,
    TfBreadcrumbComponent,
    AccordionTitleDirective,
    AccordionHeaderDirective,
    AccordionItemDirective,
    AccordionContentDirective,
    TfAccordionComponent,
    TfBadgeComponent,
    TfRadioComponent,
    TfDropdownDirective,
    TfCalendarComponent,
    TfTooltipDirective,
    TfTimePickerComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule],
  exports: [
    TfButtonComponent,
    TfInputComponent,
    TfCheckboxComponent,
    CompactTabComponent,
    TfBreadcrumbComponent,
    AccordionTitleDirective,
    AccordionHeaderDirective,
    AccordionItemDirective,
    AccordionContentDirective,
    TfAccordionComponent,
    TfBadgeComponent,
    TfRadioComponent,
    NgSelectModule,
    TfDropdownDirective,
    TfCalendarComponent,
    TfTooltipDirective,
    TfTimePickerComponent,
  ],
})
export class CoreElementsModule {}
