import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[tfDropdown]",
})
export class TfDropdownDirective {
  @HostListener("click") onClick() {
    this.toggleDropDown();
  }

  @HostListener("window:click", ["$event"]) onWindowClick(event: Event) {
    if (
      !this.element.nativeElement.contains(event.target) &&
      this.isDropdownOpen
    ) {
      this.toggleDropDown();
    }
  }

  isDropdownOpen: boolean = false;
  constructor(private element: ElementRef) {}

  toggleDropDown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.element.nativeElement.parentElement.classList.toggle("open");
    this.element.nativeElement.nextElementSibling.classList.toggle("hidden");
    this.element.nativeElement.nextElementSibling.classList.toggle("block");
    if (
      this.element.nativeElement.nextElementSibling.classList.contains("block")
    ) {
      this.element.nativeElement.nextElementSibling.style = `position: absolute; top: 50px; right: 5px; margin: 0px; transform: translate3d(0px, 0px, 0px);`;
    } else {
      this.element.nativeElement.nextElementSibling.style = "";
    }
  }
}
