import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../core/services/auth.service";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private logger: NGXLogger,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg;
        if (error.error instanceof ErrorEvent) {
          this.logger.log("This is client side error");
          errorMsg = `Error: ${error.error.message}`;
        } else {
          this.logger.log("This is server side error");
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        this.logger.error(errorMsg, error.status);
        if (error.status === 401) {
          this.authService.refreshToken().catch((err: boolean) => {
            this.logger.debug("error occurred on refreshing session: ", err);
            this.router
              .navigate(["/account/login"], {
                queryParams: { reload: true },
              })
              .then();
          });
        }
        return throwError(error);
      }),
    );
  }
}
