<div class="bg-pink-400 border text-white rounded-lg p-4" role="alert"
     *ngIf="!channelStatus && type === 'CHANNEL_STATUS'">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
           viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
           stroke-linejoin="round">
        <circle cx="12" cy="12" r="10"/>
        <path d="M12 16v-4"/>
        <path d="M12 8h.01"/>
      </svg>
    </div>
    <div class="ms-3">
      <div class="text-sm font-semibold" [innerHTML]="message" *ngIf="!showNonVerifiedText">
        <div class="text-sm font-semibold" *ngIf="showNonVerifiedText">
          You've not verified any email identities for your store. Verify at least
          one email identity to send campaigns or to set up automation journeys.
        
        </div>
        <div class="mt-4">
          <div class="flex space-x-3">
            <a type="button"
               class="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  disabled:opacity-50 disabled:pointer-events-none"
               href="{{ link.hrefVerify }}" *ngIf="link && showNonVerifiedText">
              {{ link?.textVerify }}
            </a>
            <a type="button"
               class="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  disabled:opacity-50 disabled:pointer-events-none"
               href="{{ link.href }}" *ngIf="link && !showNonVerifiedText">
              {{ link?.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-red-400 border text-white rounded-lg p-4" role="alert"
     *ngIf="!creditStatus && type === 'CREDIT_STATUS'">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
           viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
           stroke-linejoin="round">
        <circle cx="12" cy="12" r="10"/>
        <path d="M12 16v-4"/>
        <path d="M12 8h.01"/>
      </svg>
    </div>
    <div class="ms-3">
      <div class="text-sm font-semibold" [innerHTML]="message">
      </div>
      <div class="mt-4">
        <div class="flex space-x-3">
          <a type="button"
             class="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  disabled:opacity-50 disabled:pointer-events-none"
             href="{{ link.href }}" *ngIf="link">
            {{
              link?.text
            }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
