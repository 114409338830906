import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from "@angular/forms";

@Component({
  selector: "tf-input",
  templateUrl: "./tf-input.component.html",
  styleUrls: ["./tf-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TfInputComponent,
      multi: true,
    },
  ],
})
export class TfInputComponent implements ControlValueAccessor, OnInit {
  @Input() elementId!: string;
  @Input() icon?: string;
  @Input() name!: string;
  @Input() helperText?: string;
  @Input() type?: string = "text";
  @Input() additionalWrapperClass?: string;
  @Input() placeholder?: string;
  @Input() additionalHelperTextClasses?: string;
  @Output() onInputBlur: EventEmitter<void> = new EventEmitter<void>();

  inputControl: UntypedFormControl = new UntypedFormControl("");

  onChangeFn!: Function;
  onTouchedFn!: Function;

  ngOnInit() {
    this.inputControl.valueChanges.subscribe((res) => {
      if (this.onChangeFn) {
        this.onChangeFn(res);
      }
    });
  }

  onBlur() {
    this.onInputBlur.emit();
  }

  writeValue(obj: string) {
    this.inputControl.setValue(obj);
  }

  registerOnChange(fn: Function) {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }
}
