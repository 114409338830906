<div class="w-full {{additionalWrapperClass}}">
  <label [for]="elementId" class="block text-sm font-medium mb-2" *ngIf="name">{{ name }}</label>
  <div class="relative border rounded-lg">
    <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none px-1" *ngIf="icon">
      <i class="{{icon}}"></i>
    </div>
    <input [type]="type" [id]="elementId" name="hs-validation-name-error"
           [placeholder]="placeholder"
           class="py-3 px-4 block w-full border-secondary-500 rounded-lg text-sm focus:border-secondary-500 focus:ring-secondary-500
                  {{icon? 'ps-6': ''}}"
           [formControl]="inputControl"
           (blur)="onBlur()"
    >
    <div class="absolute inset-y-0 end-0 flex items-center pe-3">
      <ng-content></ng-content>
    </div>
  </div>
  <p class="text-xs {{additionalHelperTextClasses}} mt-2" id="{{elementId}}+'-helper'"
     *ngIf="helperText">{{ helperText }}</p>
</div>
