<div class="fixed top-[4.5rem] right-10">
    <div
            class="max-w-xs w-[20.5rem] bg-white border border-gray-200 rounded-xl shadow-lg mb-2"
            role="alert"
            [@fade]="toastList.length"
            *ngFor="let toast of toastList"
    >
        <div class="flex p-4 items-center">
            <div class="flex-shrink-0">
        <span
                class="inline-flex flex-shrink-0 items-center justify-center h-[1.85rem] w-[1.85rem] text-sm font-semibold leading-none rounded-full me-4"
                [ngClass]="{
          'bg-teal-100 text-teal-800': toast?.type === 'success' || !toast?.type,
          'bg-red-100 text-red-800': toast?.type === 'error',
            }"
        >
          <i
                  [ngClass]="{'bi bi-shield-x' : toast?.type === 'error',
                  'bi bi-check-circle': toast?.type === 'success'
                   }"
                  class="text-xl font-semibold"
          ></i>
        </span>
            </div>
            <div class="ms-3">
                <p class="text-sm font-semibold">
                    {{ toast?.message }}
                </p>
                <p class="text-xs text-gray-500" *ngIf="toast?.description">
                    {{ toast?.description }}
                </p>
            </div>
        </div>
    </div>
</div>
