import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {IStaticData} from "../../../../core/interfaces/store.interface";
import {ModalService} from "../../../../core/services/modal.service";

@Component({
  selector: "tf-choose-goals",
  templateUrl: "./choose-goals.component.html",
  styleUrls: ["./choose-goals.component.scss"],
})
export class ChooseGoalsComponent {
  @Input() data: IStaticData[];

  goalControl: FormControl = new FormControl<any>("");
  selectedCategory: number;

  constructor(private modalService: ModalService) {
  }

  close(value?: string) {
    value ? this.modalService.close(value) : this.modalService.close();
  }
}
