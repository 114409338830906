export const FormFieldErrorMessages = {
  REQUIRED: "This field is required",
  CAMPAIGN_DESCRIPTION_LENGTH_LIMIT:
    "Description should be less than 100 characters",
  CAMPAIGN_HOLD_OUT_PERCENTAGE: "Percentage should be in between 0 & 100",
  PAST_DATE: "Date should be future date",
  INVALID_DAY_OR_HOUR: "Should be greater than 0",
  START_DATE_GREATER_THAN_END_DATE:
    "End date should be greater then start date",
  INVALID_METRIC_VALUE: "Metric Value should be a number",
  MAX_AMOUNT_EXCEED: "Maximum $10,000 can be added once at a time",
  NEGATIVE_NUMBER: "Should be greater than 0",
  INVALID_DOMAIN: "Should be a valid domain",
  INVALID_TIME: "Should be a valid integer",
  INVALID_EMAIL: "Should be a valid email address",
};

export const CognitoMessages = {
  "UserNotConfirmedException: User is not confirmed.":
    "We have resent the confirmation code to your registered email id. Please verify your account to proceed.",
  "UserNotFoundException: User does not exist.":
    "We are unable to find this user, please sign up first",
  "NotAuthorizedException: Incorrect username or password.":
    "User Name or Password is incorrect, Try resetting the password if you couldn't recollect the email or password",
  "UserNotFoundException: Username/client id combination not found.": "",
};
