<div
    class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
>
  <div class="flex items-center justify-between pt-3 px-4">
    <h5 class="text-lg font-semibold text-gray-800">Switch Store</h5>
    <span
        class="p-2 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
        (click)="close()"
    >
      <i class="bi-x-lg scale-125"></i>
    </span>
  </div>
  <div class="p-4 overflow-y-auto">
    <tf-input [formControl]="search" placeholder="Search Store" type="text"
              elementId="switch-store-search-input"/>
    <div class="overflow-auto" [style.height.px]="350">
      <ul
          class="flex flex-col divide-y divide-gray-200"
          *ngIf="shopifyStores$ | async as shopifyStores; else loading"
      >
        <li
            class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 relative cursor-pointer hover:bg-gray-100"
        >
          <i class="bx bxl-shopify text-4xl text-green-700"></i>
          <div class="ps-3">
            <h6 class="text-md font-semibold">
              {{
                    currentStore?.store_name
                        ? currentStore.store_name
                        : currentStore?.store_url
              }}
            </h6>
            <p class="mb-0 text-xs text-blue-500">
              {{ currentStore?.store_url }}
            </p>
            <span
                class="inline-flex mt-2 items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
            >Current Store</span
            >
          </div>
          <i class="bi bi-arrow-right text-xl absolute right-[30px]"></i>
        </li>
        <ng-container *ngFor="let store of shopifyStores; let i = index">
          <li
              class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 relative cursor-pointer hover:bg-gray-100"
              *ngIf="store.fwdStoreId !== currentStore?.fwd_store_id"
              (click)="switchAccount(store)"
          >
            <i class="bx bxl-shopify text-4xl text-green-700"></i>
            <div class="ps-3">
              <h6 class="text-md font-semibold">
                {{ store?.storeName ? store.storeName : store?.storeUrl }}
              </h6>
              <p class="mb-0 text-xs text-blue-500">{{ store?.storeUrl }}</p>
            </div>
            <i class="bi bi-arrow-right text-xl absolute right-[30px]"></i>
          </li>
        </ng-container>
      </ul>
      <ng-template #loading>
        <div class="my-6">
          <tf-section-loader [loaderType]="LoaderTypes.METRIC_LOADER"/>
          <tf-section-loader [loaderType]="LoaderTypes.METRIC_LOADER"/>
          <tf-section-loader [loaderType]="LoaderTypes.METRIC_LOADER"/>
          <tf-section-loader [loaderType]="LoaderTypes.METRIC_LOADER"/>
          <tf-section-loader [loaderType]="LoaderTypes.METRIC_LOADER"/>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="flex items-center justify-end modal-footer p-4 pt-0">
    <tf-button
        (onClick)="close()"
        [routerLink]="['/account/onboarding']"
        [queryParams]="{ s: 'user-initiated' }"
        name="Add Store"
    />
  </div>
</div>
<tf-section-loader [loaderType]="LoaderTypes.FULL_SECTION_CIRCLE_LOADER"
                   *ngIf="isLoading"/>
