import { Component, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";

@Component({
  selector: "tf-time-picker",
  templateUrl: "./tf-time-picker.component.html",
  styleUrls: ["./tf-time-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TfTimePickerComponent,
      multi: true,
    },
  ],
})
export class TfTimePickerComponent implements ControlValueAccessor, OnInit {
  onChangeFn!: Function;
  onTouchedFn!: Function;

  timePickerForm = new FormGroup({
    hour: new FormControl<number>(
      12,
      Validators.compose([Validators.max(23), Validators.min(0)])
    ),
    minute: new FormControl<number>(
      0,
      Validators.compose([Validators.max(59), Validators.min(0)])
    ),
  });

  ngOnInit() {
    this.timePickerForm.valueChanges.subscribe((res) => {
      if (this.onTouchedFn) {
        this.onTouchedFn(true);
      }
      if (this.onChangeFn) {
        this.onChangeFn(res);
      }
    });
  }

  writeValue(obj: { hour: number; minute: number }): void {
    this.timePickerForm.patchValue(obj);
  }

  registerOnChange(fn: Function): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timePickerForm.disable();
    } else {
      this.timePickerForm.enable();
    }
  }
}
