import { Injectable } from "@angular/core";
import {AxiosHeaders, AxiosRequestHeaders} from "axios";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { SelectedStore } from "../store/user-store/user-store.action";
import { Store } from "@ngxs/store";
import { IShopifyStore } from "../interfaces/store.interface";
import { BehaviorSubject } from "rxjs";
import { IUser } from "../interfaces/user.interface";
import { NGXLogger } from "ngx-logger";

@Injectable({ providedIn: "root" })
export class CoreBackendService {
  user: IUser;
  currentUser = {};
  authenticationHeader: AxiosRequestHeaders;

  static showSetupForm$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private authServices: AuthenticationService,
    private httpClient: HttpClient,
    private store: Store,
    private logger: NGXLogger
  ) {}

  async setAuthHeader() {
    return this.authServices.getAuthToken().then((auth) => {
      let authToken = auth.getIdToken().getJwtToken();

      this.authenticationHeader = new AxiosHeaders({
        Authorization: authToken,
      });
    });
  }

  public getEnrolledStores(): Promise<{ rows: Array<IShopifyStore> }> {
    return this.httpClient
      .get<{ rows: Array<IShopifyStore> }>(
        `${environment.baseServiceURL}${environment.API_getEnrolledStores}`
      )
      .toPromise();
  }

  updateEnrolledStores(): void {
    this.authServices
      .currentUser()
      .then((_) => {
        this.getEnrolledStores().then((res) => {
          if (res.rows.length > 0) {
            this.updateStore(res.rows);
          }
        });
      })
      .catch((_) => {
        this.logger.error("[user not logged in]");
      });
  }

  updateStore(store: Array<IShopifyStore>) {
    this.store.dispatch(new SelectedStore(store[0]));
  }
}
