import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";

interface IPaginationComponent {
  currentPage: number;
  totalPages: number;
  changePageContent: EventEmitter<number>;

  changePage(pageNumber: number): void;
}

@Component({
  selector: "tf-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent
  implements IPaginationComponent, OnChanges, OnInit
{
  @Input() currentPage: number = 0;
  @Input() totalPages: number;
  @Output() changePageContent: EventEmitter<number> =
    new EventEmitter<number>();

  pageNumbers: Array<number> = [];

  constructor(private logger: NGXLogger) {}

  ngOnInit() {
    this.generatePageNumbers(this.totalPages);
    this.logger.debug(this.currentPage);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalPages && changes.totalPages.currentValue) {
      this.generatePageNumbers(changes.totalPages.currentValue);
    }
  }

  changePage(pageNumber: number): void {
    if (pageNumber < 0 || pageNumber > this.totalPages) {
      return;
    }
    this.currentPage = pageNumber;
    this.changePageContent.emit(this.currentPage);
  }

  private generatePageNumbers(totalPages: number): void {
    this.pageNumbers = [];
    for (let i = 1; i < totalPages; i++) {
      this.pageNumbers.push(i);
    }
  }

  get somePageNumbers(): Array<number> {
    if (this.pageNumbers.length > 5) {
      return this.pageNumbers.slice(this.currentPage, this.currentPage + 5);
    } else {
      return this.pageNumbers;
    }
  }
}
