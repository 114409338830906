export const ToastMessages = {
  COPY_TO_CLIPBOARD: "Successfully copied to clipboard",
  SOMETHING_WENT_WRONG: "Something went wrong, Please try again",
  FILE_UPLOAD_SUCCESS: "The media files have been successfully uploaded",
};

// TODO: add different message for journey and campaign
export const AlertToastMessage = {
  EMAIL_CHANNEL_NOT_ENABLED: ` <b>Email Channel</b> has not been enabled on this store. Please considering enabling it before creating any email campaign and/or journey`,
  SMS_CHANNEL_NOT_ENABLED: ` <b>SMS Channel</b> has not been enabled on this store. Please considering enabling it before creating any SMS campaign and/or journey`,
  EMAIL_SMS_CHANNEL_NOT_ENABLED: `Enable at least one channel to send campaigns or journey`,
  EMAIL_CHANNEL_NOT_VERIFIED: `Create at least one verified email identity to send email campaigns or journey`,
  LOW_CREDIT: `<b>Low Credits!</b> Add more to ensure your messages get delivered.`,
  JOURNEY_NOT_AVAILABLE_IN_CURRENT_PLAN: `Journeys are available only on the Focus and Scale plan`,
  SMS_AVAILABILITY_BASED_ON_PLAN:
    "SMS channel is not available in your current plan. Upgrade now to start sending SMS.",
  WHATSAPP_ENABLED: `<b>Whatsapp Channel</b> has been enabled on this store.`,
  EMAIL_IDENTITY_DELETED: {
    success: (domainName: string) =>
      `The domain ${domainName} has been deleted from the list of email identities.`,
    failure: (domainName: string) =>
      `Failed to delete the domain ${domainName}.`,
  },
};
