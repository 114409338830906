import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  CampaignAvailabilityStatus,
  JourneyAvailabilityStatus,
  SegmentAvailabilityStatus,
  TriggerSetupModal,
} from "./status.action";

export class SectionAvailabilityStatusModel {
  isSegmentSectionAvailable: boolean;
  isCampaignSectionAvailable: boolean;
  isJourneySectionAvailable: boolean;
  showSetUpModal: boolean;
}
@State<SectionAvailabilityStatusModel>({
  name: "SectionAvailabilityStatus",
  defaults: {
    isSegmentSectionAvailable: true,
    isCampaignSectionAvailable: true,
    isJourneySectionAvailable: true,
    showSetUpModal: false,
  },
})
@Injectable({
  providedIn: "root",
})
export class SectionAvailabilityStatus {
  @Selector()
  static getSegmentAvailabilityStatus(state: SectionAvailabilityStatusModel) {
    return state.isSegmentSectionAvailable;
  }

  @Selector()
  static getCampaignAvailabilityStatus(state: SectionAvailabilityStatusModel) {
    return state.isCampaignSectionAvailable;
  }

  @Selector()
  static getJourneyAvailabilityStatus(state: SectionAvailabilityStatusModel) {
    return state.isJourneySectionAvailable;
  }

  @Selector()
  static getSetupModalTrigger(state: SectionAvailabilityStatusModel) {
    return state.showSetUpModal;
  }

  @Action(SegmentAvailabilityStatus)
  updateSegmentAvailabilityStatus(
    { getState, patchState }: StateContext<SectionAvailabilityStatusModel>,
    { payload }: SegmentAvailabilityStatus
  ) {
    const state = getState();
    patchState({ ...state, isSegmentSectionAvailable: payload });
  }

  @Action(CampaignAvailabilityStatus)
  updateCampaignAvailabilityStatus(
    { getState, patchState }: StateContext<SectionAvailabilityStatusModel>,
    { payload }: CampaignAvailabilityStatus
  ) {
    const state = getState();
    patchState({ ...state, isCampaignSectionAvailable: payload });
  }

  @Action(JourneyAvailabilityStatus)
  updateJourneyAvailabilityStatus(
    { getState, patchState }: StateContext<SectionAvailabilityStatusModel>,
    { payload }: JourneyAvailabilityStatus
  ) {
    const state = getState();
    patchState({ ...state, isJourneySectionAvailable: payload });
  }

  @Action(TriggerSetupModal)
  updateSetupModalTrigger(
    { getState, patchState }: StateContext<SectionAvailabilityStatusModel>,
    { payload }: TriggerSetupModal
  ) {
    const state = getState();
    patchState({ ...state, showSetUpModal: payload });
  }
}
