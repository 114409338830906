<div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
  <div class="flex items-center justify-between pt-3 px-4">
    <h5 class="text-lg font-semibold text-gray-800">Upload File</h5>
    <span
      class="p-2 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
      (click)="closeModal()"
    >
      <i class="bi-x-lg scale-125"></i>
    </span>
  </div>
  <div class="p-4 overflow-y-auto">
    
    <ngx-dropzone
      (change)="onSelect($event)"
      [multiple]="uploaderConfig?.multiple"
      [accept]="uploaderConfig?.accept"
      [maxFileSize]="uploaderConfig?.maxFileSize"
    >
      <ngx-dropzone-label>Drop your files here</ngx-dropzone-label>
      <ngx-dropzone-image-preview
        ngProjectAs="ngx-dropzone-preview"
        *ngFor="let file of uploadingFiles"
        [file]="file"
        [removable]="true"
        (removed)="onRemove(file)"
      >
        <ngx-dropzone-label>{{ file?.name }}</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ngx-dropzone>
    
    <div class="flex justify-end mt-4">
      <tf-button name="Upload Files" [disabled]="uploadingFiles.length === 0"
                 (onClick)="uploadFiles()"/>
    </div>
  </div>
</div>
