import { ICampaign } from "../../interfaces/campaign.interface";
import { Kpi } from "../../classes/kpi";

export class AddCampaign {
  static readonly type = "[Campaign] Add Campaign";

  constructor(public payload: ICampaign) {}
}

export class AddCampaignList {
  static readonly type = "[Campaign] Add Campaign List";

  constructor(public payload: Array<ICampaign>) {}
}

export class FetchCampaignList {
  static readonly type = "[Campaign] Fetch Campaign List";

  constructor(
    public count : number
  ) {}
}

export class AddCampaignMetrics {
  static readonly type = "[Campaign] Add Campaign Metrics";

  constructor(
    public deliveryMetric: any,
    public sendingMetric: any,
    public campaignId: string
  ) {}
}
