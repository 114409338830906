import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  AddDraftJourney,
  AddJourneyList,
  AddPredefinedJourney,
} from "./journey.action";
import { IJourneyV2 } from "../../interfaces/journey.interface";
import {
  IDraftJourney,
  IPredefinedJourney,
} from "../../interfaces/journey-v2.interface";

export class JourneyStateModel {
  journeys: Array<IJourneyV2>;
  selectedPreDefinedJourney: IPredefinedJourney;
  selectedDraftJourney: IDraftJourney;
}

@State<JourneyStateModel>({
  name: "journey",
  defaults: {
    journeys: [],
    selectedPreDefinedJourney: null,
    selectedDraftJourney: null,
  },
})
@Injectable({
  providedIn: "root",
})
export class JourneyState {
  @Selector()
  static getJourneys(state: JourneyStateModel) {
    return state.journeys;
  }

  @Selector()
  static getPreDefinedJourney(state: JourneyStateModel): IPredefinedJourney {
    return state.selectedPreDefinedJourney;
  }

  @Selector()
  static getDraftJourney(state: JourneyStateModel): IDraftJourney {
    return state.selectedDraftJourney;
  }

  @Selector()
  static getAvailableActivityList(state: JourneyStateModel): Array<string> {
    return state.selectedPreDefinedJourney.template.allowedActivities;
  }

  @Action(AddJourneyList)
  addJourneyList(
    { patchState }: StateContext<JourneyStateModel>,
    { payload }: AddJourneyList
  ) {
    patchState({ journeys: payload });
  }

  @Action(AddPredefinedJourney)
  addPredefinedJourney(
    { patchState }: StateContext<JourneyStateModel>,
    { payload }: AddPredefinedJourney
  ) {
    patchState({ selectedPreDefinedJourney: payload });
  }

  @Action(AddDraftJourney)
  addDraftJourney(
    { patchState }: StateContext<JourneyStateModel>,
    { payload }: AddDraftJourney
  ) {
    patchState({ selectedDraftJourney: payload });
  }
}
