import { IAttribute, ISegment } from "../../interfaces/segment.interface";

export class AddSegmentList {
  static readonly type = "[Segment] Add Segment list";

  constructor(public payload: Array<ISegment>) {}
}

export class AddSegmentAttributeList {
  static readonly type = "[Segment] Add segment attribute list";

  constructor(public payload: Array<IAttribute>) {}
}

export class UpdateSegmentCount {
  static readonly type = "[Segment] Update segment count";

  constructor(public count: number) {}
}

export class onSwitchStoreSegmentList {
  static readonly type = "[Segment] On switch Store Segment List";

  constructor() {}
}
