<!-- Datepicker -->
<input
  class="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-gray-400 focus:ring-gray-400"
  value="{{ selectedDate | date : 'yyyy-MM-dd' }}"
  type="text"
  readonly
  (click)="toggle = !toggle; setPosition($event)"
  *ngIf="!isRangePicker"
/>

<div
  #calendar
  *ngIf="!isRangePicker"
  id="single-calendar-0001"
  class="w-80 md:w-[20.2rem] flex flex-col bg-white border shadow-lg rounded-xl overflow-hidden fixed {{
    toggle ? '' : 'hidden'
  }} z-[100]"
>
  <div class="p-3 grid md:flex gap-8">
    <!-- Calendar -->
    <div class="space-y-0.5">
      <!-- Months -->
      <div class="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
        <!-- Prev Button -->
        <div class="col-span-1">
          <button
            type="button"
            class="w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            (click)="setMonth(-1)"
          >
            <svg
              class="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
          </button>
        </div>
        <!-- End Prev Button -->

        <!-- Month / Year -->
        <div class="col-span-3 flex justify-center items-center gap-x-1">
          <div class="relative">
            <select [formControl]="monthControl">
              <option [value]="0">January</option>
              <option [value]="1">February</option>
              <option [value]="2">March</option>
              <option [value]="3">April</option>
              <option [value]="4">May</option>
              <option [value]="5">June</option>
              <option [value]="6">July</option>
              <option [value]="7">August</option>
              <option [value]="8">September</option>
              <option [value]="9">October</option>
              <option [value]="10">November</option>
              <option [value]="11">December</option>
            </select>
          </div>

          <div class="relative">
            <select [formControl]="yearControl">
              <option *ngFor="let year of yearList" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <!-- End Month / Year -->

        <!-- Next Button -->
        <div class="col-span-1 flex justify-end">
          <button
            type="button"
            class="opacity-1 w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            (click)="setMonth(1)"
          >
            <svg
              class="flex-shrink-0 w-4 h-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
        <!-- End Next Button -->
      </div>
      <!-- Months -->

      <!-- Weeks -->
      <div class="flex pb-1.5">
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Su
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Mo
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Tu
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          We
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Th
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Fr
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Sa
        </span>
      </div>
      <!-- Weeks -->

      <!-- Days -->
      <div class="flex" *ngFor="let week of dates">
        <div *ngFor="let date of week">
          <button
            type="button"
            class="m-px w-10 h-10 flex justify-center items-center border border-transparent text-sm text-gray-800 hover:border-blue-600 hover:text-blue-600 rounded-full disabled:text-gray-300 disabled:pointer-events-none"
            (click)="updateSelectedDate(date)"
            [ngClass]="{
              'text-blue-600 border-blue-600 bg-blue-100':
                date?.getDate() === selectedDate?.getDate() &&
                date?.getMonth() === selectedDate?.getMonth() &&
                date?.getFullYear() === selectedDate?.getFullYear(),
              'opacity-0': date?.getMonth() != monthControl.value
            }"
          >
            {{ date?.getDate() }}
          </button>
        </div>
      </div>
      <!-- Days -->
    </div>
    <!-- End Calendar -->

    <!-- End Calendar -->
  </div>
</div>

<input
  class="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-gray-400 focus:ring-gray-400"
  value="{{ selectedDate | date : 'yyyy-MM-dd' }}- {{
    selectedSecondDate | date : 'yyyy-MM-dd'
  }}"
  type="text"
  readonly
  (click)="toggle = !toggle; setPosition($event)"
  *ngIf="isRangePicker"
/>
<div
  #calendar
  *ngIf="isRangePicker"
  class="w-80 {{
    isRangePicker ? 'md:w-[40.4rem]' : 'md:w-[20.2rem]'
  }} flex flex-col bg-white border shadow-lg rounded-xl overflow-hidden fixed {{
    toggle ? '' : 'hidden'
  }} z-[100]"
>
  <div class="p-3 grid md:flex gap-8">
    <!-- Calendar -->
    <div class="space-y-0.5">
      <!-- Months -->
      <div class="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
        <!-- Prev Button -->
        <div class="col-span-1">
          <button
            type="button"
            class="w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            (click)="setMonth(-1)"
          >
            <svg
              class="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
          </button>
        </div>
        <!-- End Prev Button -->

        <!-- Month / Year -->
        <div class="col-span-3 flex justify-center items-center gap-x-1">
          <div class="relative">
            <select [formControl]="monthControl">
              <option value="0">January</option>
              <option value="1">February</option>
              <option value="2">March</option>
              <option value="3">April</option>
              <option value="4">May</option>
              <option value="5">June</option>
              <option value="6" selected>July</option>
              <option value="7">August</option>
              <option value="8">September</option>
              <option value="9">October</option>
              <option value="10">November</option>
              <option value="11">December</option>
            </select>
          </div>

          <div class="relative">
            <select [formControl]="yearControl">
              <option *ngFor="let year of yearList" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <!-- End Month / Year -->

        <!-- Next Button -->
        <div class="col-span-1 flex justify-end">
          <button
            type="button"
            class="opacity-1 w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            (click)="setMonth(1)"
          >
            <svg
              class="flex-shrink-0 w-4 h-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
        <!-- End Next Button -->
      </div>
      <!-- Months -->

      <!-- Weeks -->
      <div class="flex pb-1.5">
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Su
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Mo
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Tu
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          We
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Th
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Fr
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Sa
        </span>
      </div>
      <!-- Weeks -->

      <!-- Days -->
      <div class="flex" *ngFor="let week of dates">
        <div
          *ngFor="let date of week"
          [ngClass]="{
            'bg-blue-50':
              date?.getTime() > selectedDate?.getTime() &&
              date?.getTime() < selectedSecondDate?.getTime()
          }"
        >
          <button
            type="button"
            class="m-px w-10 h-10 flex justify-center items-center border border-transparent text-sm text-gray-800 hover:border-blue-600 hover:text-blue-600 disabled:text-gray-300 disabled:pointer-events-none rounded-full"
            (click)="updateSelectedDate(date)"
            [ngClass]="{
              'text-blue-600 border-blue-600 bg-blue-100 rounded-r':
                date?.getDate() === selectedDate?.getDate() &&
                date?.getMonth() === selectedDate?.getMonth() &&
                date?.getFullYear() === selectedDate?.getFullYear(),
              'text-blue-600 border-blue-600 bg-blue-100 rounded-l':
                date?.getDate() === selectedSecondDate?.getDate() &&
                date?.getMonth() === selectedSecondDate?.getMonth() &&
                date?.getFullYear() === selectedSecondDate?.getFullYear(),
              'opacity-0': date?.getMonth() != monthControl.value
            }"
          >
            {{ date?.getDate() }}
          </button>
        </div>
      </div>
      <!-- Days -->
    </div>
    <div class="space-y-0.5">
      <!-- Months -->
      <div class="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
        <!-- Prev Button -->
        <div class="col-span-1">
          <button
            type="button"
            class="w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            (click)="setSecondMonth(-1)"
          >
            <svg
              class="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
          </button>
        </div>
        <!-- End Prev Button -->

        <!-- Month / Year -->
        <div class="col-span-3 flex justify-center items-center gap-x-1">
          <div class="relative">
            <select [formControl]="secondMonthControl">
              <option value="0">January</option>
              <option value="1">February</option>
              <option value="2">March</option>
              <option value="3">April</option>
              <option value="4">May</option>
              <option value="5">June</option>
              <option value="6" selected>July</option>
              <option value="7">August</option>
              <option value="8">September</option>
              <option value="9">October</option>
              <option value="10">November</option>
              <option value="11">December</option>
            </select>
          </div>
          
          <div class="relative">
            <select [formControl]="secondYearControl">
              <option *ngFor="let year of yearList" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <!-- End Month / Year -->

        <!-- Next Button -->
        <div class="col-span-1 flex justify-end">
          <button
            type="button"
            class="opacity-1 w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            (click)="setSecondMonth(1)"
          >
            <svg
              class="flex-shrink-0 w-4 h-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
        <!-- End Next Button -->
      </div>
      <!-- Months -->

      <!-- Weeks -->
      <div class="flex pb-1.5">
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Su
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Mo
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Tu
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          We
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Th
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Fr
        </span>
        <span class="m-px w-10 block text-center text-sm text-gray-500">
          Sa
        </span>
      </div>
      <!-- Weeks -->

      <!-- Days -->
      <div class="flex" *ngFor="let week of secondCalendarDates">
        <div
          *ngFor="let date of week"
          [ngClass]="{
            'bg-blue-50':
              date?.getTime() > selectedDate?.getTime() &&
              date?.getTime() < selectedSecondDate?.getTime()
          }"
        >
          <button
            type="button"
            class="m-px w-10 h-10 flex justify-center items-center border border-transparent text-sm text-gray-800 hover:border-blue-600 hover:text-blue-600 rounded-full disabled:text-gray-300 disabled:pointer-events-none"
            (click)="updateSecondDate(date)"
            [ngClass]="{
              'text-blue-600 border-blue-600 bg-blue-100 rounded-r':
                date?.getDate() === selectedDate?.getDate() &&
                date?.getMonth() === selectedDate?.getMonth() &&
                date?.getFullYear() === selectedDate?.getFullYear(),
              'text-blue-600 border-blue-600 bg-blue-100 rounded-l':
                date?.getDate() === selectedSecondDate?.getDate() &&
                date?.getMonth() === selectedSecondDate?.getMonth() &&
                date?.getFullYear() === selectedSecondDate?.getFullYear(),
              'opacity-0': date?.getMonth() !== secondMonthControl.value,
              'opacity-25 no-pointer-events':
                yearControl.value > secondYearControl.value ||
                (yearControl.value == secondYearControl.value &&
                  monthControl.value >= secondMonthControl.value)
            }"
          >
            {{ date?.getDate() }}
          </button>
        </div>
      </div>
      <!-- Days -->
    </div>
    <!-- End Calendar -->

    <!-- End Calendar -->
  </div>
</div>
<!-- End Datepicker -->
