import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  BUTTON_STYLES,
  BUTTON_STYLES_TYPE,
} from "../../../core/constants/button.constant";

@Component({
  selector: "tf-button",
  templateUrl: "./tf-button.component.html",
  styleUrls: ["./tf-button.component.scss"],
})
export class TfButtonComponent {
  @Input() name!: string;
  @Input() additionalStyle?: string;
  @Input() disabled?: boolean = false;
  @Input() type?: string = "button";
  @Input() buttonStyle?: string = BUTTON_STYLES_TYPE.PRIMARY;
  @Input() showLoader?: boolean = false;
  @Input() adjustSpaceForLoader?: boolean = false;

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  onClicked(e) {
    this.onClick.emit(e);
  }

  protected readonly BUTTON_STYLES = BUTTON_STYLES;
}
