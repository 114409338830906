import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CurrentUser } from "./user.action";
import { IUser } from "../../interfaces/user.interface";

export class UserStateModel {
  currentUser: IUser;
}

@State<UserStateModel>({
  name: "user",
  defaults: {
    currentUser: undefined,
  },
})
@Injectable({
  providedIn: "root",
})
export class UserStoreState {
  @Selector()
  static getCurrentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Action(CurrentUser)
  currentUserState(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: CurrentUser
  ) {
    const state = getState();
    const { attributes } = payload;
    patchState({
      ...state,
      currentUser: {
        ...attributes,
      },
    });
  }
}
