<div class="relative flex items-start">
  <div class="flex items-center h-5" [ngClass]="{'mt-1': description}">
    <input [id]="id" [name]="group" type="checkbox"
           class="border-gray-200 rounded text-blue-600 focus:ring-secondary-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-secondary-500 dark:checked:border-secondary-500 dark:focus:ring-offset-gray-800"
           [checked]="checked"
           (change)="onChanged($event.target?.checked)">
  </div>
  <label [for]="id" class="ms-3">
    <span class="block text-sm font-semibold text-gray-800">{{ name }}</span>
    <span id="{{id}}-description" class="block text-sm text-gray-600" *ngIf="description">{{ description }}</span>
  </label>
</div>
