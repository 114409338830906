import { IChannel } from "../../interfaces/channel.interface";

export class updateChannelStatus {
  static readonly type = "[Identity] update channel status";
  constructor(public payload: Array<IChannel>) {}
}

export class UpdateVerifiedEmailIdentity {
  static readonly type = "[Identity] update verified identity";
  constructor(public payload: boolean) {}
}

/**
 * @description On switching store cleaning the identity state
 */
export class OnSwitchStoreIdentity {
  static readonly type = "[Identity] On switch Store";

  constructor() {
  }
}
