<div class="h-[10px]" *ngIf="!directMessageMode">
  <p
    *ngIf="(errorControl?.invalid && errorControl?.touched) || force"
    class="mb-0 text-xs text-red-600"
  >
    {{ getErrorMessage() }}
  </p>
</div>
<div class="h-[10px]" *ngIf="directMessageMode">
  <p class="mb-0 text-xs text-red-600">
    {{ message }}
  </p>
</div>
