import { Component, Input, OnInit } from "@angular/core";
import heic2any from "heic2any";
import { ToastMessages } from "../../../core/constants/toast-message.constant";
import { DataService } from "../../../core/services/data.service";
import { NGXLogger } from "ngx-logger";
import { ModalService } from "../../../core/services/modal.service";
import { ToastService } from "../../../core/services/toast.service";

@Component({
  selector: "tf-media-upload",
  templateUrl: "./media-upload.component.html",
  styleUrls: ["./media-upload.component.scss"],
})
export class MediaUploadComponent implements OnInit {
  @Input() currentFolder: string;
  @Input() uploaderConfig: {
    maxFileSize: number;
    accept: string;
    multiple: boolean;
  };
  uploadingFiles: Array<File> = [];
  isLoading: boolean = false;

  constructor(
    private dataService: DataService,
    private toastService: ToastService,
    private logger: NGXLogger,
    private activeModal: ModalService
  ) {}

  ngOnInit() {
    console.log(this.currentFolder, this.uploaderConfig);
  }

  onSelect(e: {
    addedFiles: Array<File>;
    rejectedFiles: Array<File>;
    source: any;
  }): void {
    const heicFiles = [];
    const otherFiles = [];
    e.addedFiles.forEach((file) => {
      file.type === "image/heic" ? heicFiles.push(file) : otherFiles.push(file);
    });

    if (heicFiles.length > 0) {
      Promise.all(heicFiles.map((res) => this._convertHEICFile(res))).then(
        (res) => {
          this.uploadingFiles.push(...res);
        }
      );
    }
    this.uploadingFiles.push(...otherFiles);
  }

  /**
   * Converting the heic file to jpeg format
   * @param {File} file Heic file object
   * @return {File} jpeg file
   */
  async _convertHEICFile(file: File): Promise<File> {
    try {
      const blobUrl = URL.createObjectURL(file);
      const blob = await (await fetch(blobUrl)).blob();
      const fileBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.9,
      });
      return new File([fileBlob as any], `${file.name}.jpeg`, {
        type: "image/jpeg",
      });
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  uploadFiles(): void {
    Promise.all(
      this.uploadingFiles.map((file: File) =>
        this.dataService.uploadFiles(
          file,
          this.currentFolder,
          file.name.replace(/[\s\%\-]/g, "_") // replacing all any space in the file name to underscore
        )
      )
    )
      .then((_) => {
        const result = {
          success: true,
          uploadedFiles: [...this.uploadingFiles],
        };
        this.uploadingFiles = [];
        this.toastService.showToast({
          message: ToastMessages.FILE_UPLOAD_SUCCESS,
          icon: "bx bx-check",
        });
        this.activeModal.close(result);
      })
      .catch((err) => {
        this.logger.error("Error while uploading file: ", err);
        this.toastService.showToast({
          message: ToastMessages.SOMETHING_WENT_WRONG,
          icon: "bx bx-x",
        });
      });
  }

  onRemove(file: File): void {
    const itemIndex = this.uploadingFiles.findIndex(
      (res) => res.name === file.name
    );
    this.uploadingFiles.splice(itemIndex, 1);
  }

  closeModal() {
    this.activeModal.close({ success: null });
  }
}
