import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "ngx-localstorage";
import { StorageKeys } from "../constants/storageKeys.constant";
import { AuthenticationService } from "./auth.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class CommonUtilService {
  private activeCustomerId: string;

  constructor(
    private storageService: LocalStorageService,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  getActiveCustomerId(): string {
    if (!this.activeCustomerId) {
      this.activeCustomerId = this.storageService.get(StorageKeys.CUSTOMER_ID);
      this.isValidCustomerId(this.activeCustomerId);
    }
    return this.activeCustomerId;
  }

  resetUserId(): void {
    this.activeCustomerId = null;
  }

  isValidCustomerId(customerId: string): void {
    if (!customerId) {
      this.authService.logout().then();
      this.router
        .navigate(["/account/login"], {
          queryParams: { reload: true },
        })
        .then();
      this.toastService.showToast({
        message: `Some necessary fields are missing, Please login again`,
        icon: "bx bx-x",
      });
    }
  }
}
