import {Component, Input} from "@angular/core";
import {BADGE_STYLES} from "../../../core/constants/button.constant";

@Component({
  selector: "tf-badge",
  templateUrl: "./tf-badge.component.html",
  styleUrls: ["./tf-badge.component.scss"],
})
export class TfBadgeComponent {
  @Input() additionalClasses?: string;
  @Input() badgeType?: string = BADGE_STYLES.DEFAULT;
}
