import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ITabItem } from "../../../core/interfaces/core-elements.interface";

@Component({
  selector: "tf-compact-tab",
  templateUrl: "./compact-tab.component.html",
  styleUrls: ["./compact-tab.component.scss"],
})
export class CompactTabComponent {
  @Input() tabItems!: Array<ITabItem>;
  @Input() tabGroup!: string;

  @Output() onTabSwitch: EventEmitter<ITabItem> = new EventEmitter<ITabItem>();

  tabSwitch(tabItem: ITabItem): void {
    this.tabItems.forEach((tab) => {
      tab.active = tab.id === tabItem.id;
    });
    this.onTabSwitch.emit(tabItem);
  }
}
