import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { ICampaign } from "../../interfaces/campaign.interface";
import {
  AddCampaign,
  AddCampaignList,
  AddCampaignMetrics,
  FetchCampaignList,
} from "./campaign.action";
import { CampaignService } from "../../services/campaign.service";

export class CampaignStateModel {
  campaigns: Array<ICampaign>;
  campaignMap: { [key in string]: ICampaign };
  campaignMetrics: { [key in string]: any };
}

@State<CampaignStateModel>({
  name: "campaigns",
  defaults: {
    campaigns: [],
    campaignMap: {},
    campaignMetrics: {},
  },
})
@Injectable({
  providedIn: "root",
})
export class CampaignState {
  constructor(private campaignService: CampaignService, private store: Store) {}

  @Selector()
  static getCampaigns(state: CampaignStateModel) {
    return state.campaigns;
  }

  @Selector()
  static getCampaignMap(state: CampaignStateModel) {
    return state.campaignMap;
  }

  @Selector()
  static getCampaignMetrics(state: CampaignStateModel) {
    return state.campaignMetrics;
  }

  @Action(AddCampaign)
  addCampaign(
    { getState, patchState }: StateContext<CampaignStateModel>,
    { payload }: AddCampaign
  ) {
    const state = getState();
    const campaignMap = { ...state.campaignMap, [payload.Id]: payload };
    patchState({ campaigns: [...state.campaigns, payload], campaignMap });
  }

  @Action(AddCampaignList)
  addCampaignList(
    { patchState }: StateContext<CampaignStateModel>,
    { payload }: AddCampaignList
  ) {
    if (payload.length > 0) {
      patchState({
        campaigns: payload,
        campaignMap: this._generateCampaignMap(payload),
      });
    }
  }

  @Action(AddCampaignMetrics)
  updateCampaignMetrics(
    { patchState, getState }: StateContext<CampaignStateModel>,
    { deliveryMetric, sendingMetric, campaignId }: AddCampaignMetrics
  ) {
    const state = getState();
    const metricsState = { ...state.campaignMetrics };
    metricsState[campaignId] = { sendingMetric, deliveryMetric };
    patchState({ ...state, campaignMetrics: metricsState });
  }

  _generateCampaignMap(campaignList: Array<ICampaign>): {
    [key in string]: ICampaign;
  } {
    const campaignMap: { [key in string]: ICampaign } = {};
    campaignList.map((res: ICampaign) => {
      campaignMap[res.Id] = res;
    });
    return campaignMap;
  }

  @Action(FetchCampaignList)
  fetchAllCampaigns(
    { patchState }: StateContext<CampaignStateModel>,
    { count }: FetchCampaignList) {
    this.campaignService
      .getCampaignList(0, count)
      .then((res: { campaignList: ICampaign[] }) => {
        this.store.dispatch(new AddCampaignList(res.campaignList));
      });
  }
}
