import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { Auth } from "aws-amplify";
import { LocalStorageService } from "ngx-localstorage";
import { environment } from "../../../../environments/environment";
import { OnboardingStatus } from "../../constants/onboarding.constant";
import {
  StorageKeys,
  THRESHOLD_AMOUNT,
} from "../../constants/storageKeys.constant";
import { IShopifyStore } from "../../interfaces/store.interface";
import {
  CampaignAvailabilityStatus,
  JourneyAvailabilityStatus,
  SegmentAvailabilityStatus,
  TriggerSetupModal,
} from "../status/status.action";
import {
  CurrentSubscription,
  OnSwitchStore,
  SelectedStore,
  UpdateWallet,
} from "./user-store.action";
import { CommonUtilService } from "../../services/common.service";
import { CrispService } from "../../services/crisp.service";
import { CRISP_STATUS } from "../../enums/message-type.enum";
import { IPlan } from "../../interfaces/subscriptionPlans.interface";
import { PaymentService } from "../../services/payment.service";
import { IWallet } from "../../interfaces/credit.interface";
import { NGXLogger } from "ngx-logger";

declare let mixpanel: any;

export class ShopifyStoreStateModel {
  shopifyStores: Array<IShopifyStore>;
  selectedStore: IShopifyStore;
  currency: string;
  currentSubscription: IPlan;
  wallet: IWallet;
}

@State<ShopifyStoreStateModel>({
  name: "ShopifyStores",
  defaults: {
    shopifyStores: [],
    selectedStore: undefined,
    currency: undefined,
    currentSubscription: undefined,
    wallet: undefined,
  },
})
@Injectable({
  providedIn: "root",
})
export class ShopifyStoreState {
  worker: Worker | null = null;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private store: Store,
    private crispService: CrispService,
    private commonService: CommonUtilService,
    private paymentServices: PaymentService,
    private logger: NGXLogger,
  ) {}

  @Selector()
  static getShopifyStores(state: ShopifyStoreStateModel) {
    return state.shopifyStores;
  }

  @Selector()
  static getCurrentWallet(state: ShopifyStoreStateModel) {
    return state.wallet;
  }

  @Selector()
  static getSelectedShopifyStore(state: ShopifyStoreStateModel) {
    const currencyCode =
      state.selectedStore?.additional_info?.money_with_currency_format
        ?.split("{{amount}}")
        ?.join("") || "";
    return {
      ...state.selectedStore,
      additional_info: {
        ...state.selectedStore.additional_info,
        money_with_currency_format: currencyCode,
      },
    };
  }

  @Selector()
  static getCurrency(state: ShopifyStoreStateModel) {
    return state.selectedStore?.additional_info?.currency;
  }

  @Selector()
  static getTimezone(state: ShopifyStoreStateModel) {
    return (
      state.selectedStore?.additional_info?.timezone
        ?.split(" ")[0]
        ?.split("GMT")[1]
        ?.split(":")
        ?.join("") || "+0000"
    );
  }

  @Selector()
  static getCurrentSubscription(state: ShopifyStoreStateModel) {
    return state.currentSubscription;
  }

  @Action(SelectedStore)
  setSelectedStore(
    { patchState, getState }: StateContext<ShopifyStoreStateModel>,
    { payload }: SelectedStore,
  ) {
    const state = getState();
    if (payload?.is_disabled) {
      this.router.navigate(["blocked"]).then();
    }
    this.crispService.trackerEnabled(
      payload.tracker_status ? CRISP_STATUS.YES : CRISP_STATUS.NO,
    );

    patchState({
      ...state,
      selectedStore: payload,
      currency: payload?.additional_info?.currency,
    });
    this._manageStore(payload.status);
    this.commonService.resetUserId();
    this._updateCurrentStoreInfo(payload);
    this._triggerMixPanel(payload);
    this.store.dispatch(new CurrentSubscription());
    this.store.dispatch(new UpdateWallet());
  }

  _extractCurrencyCode(selectedStore: any): string {
    const currencyCodeMatch =
      selectedStore?.additional_info?.money_with_currency_format?.match(
        /[A-Za-z]+/,
      );
    if (currencyCodeMatch) {
      return currencyCodeMatch[0];
    } else {
      console.error(
        "Currency code not found in money_with_currency_format field.",
      );
      return ""; // Return empty string if currency code not found
    }
  }

  _updateCurrentStoreInfo(payload: IShopifyStore): void {
    this.storageService.set(StorageKeys.CUSTOMER_ID, payload.fwd_store_id);
    this.storageService.set(StorageKeys.STORE_PREFIX, payload.airbyte_prefix);
  }

  _triggerMixPanel(store: IShopifyStore) {
    if (environment.production) {
      mixpanel.register_once({
        ActiveStore: store.store_url,
        AdditionalStoreInfo: store,
      });
      mixpanel.people.set({
        ActiveStore: store.store_url,
      });
    }
  }

  _manageStore(storeStatus: string): void {
    const storeId = this.storageService.get(StorageKeys.CUSTOMER_ID) as string;
    if (storeStatus === OnboardingStatus.SyncInProgress) {
      this.store.dispatch(new SegmentAvailabilityStatus(false));
      this.store.dispatch(new CampaignAvailabilityStatus(false));
      this.store.dispatch(new JourneyAvailabilityStatus(false));
      this._triggerStoreStatusCheck({
        url: `${environment.baseServiceURL}/customer/${storeId}/status`,
        storeId,
      }).then();
    } else if (storeStatus === OnboardingStatus.SyncCompleted) {
      this.store.dispatch(new TriggerSetupModal(true));
    } else {
      this.store.dispatch(new SegmentAvailabilityStatus(true));
      this.store.dispatch(new CampaignAvailabilityStatus(true));
      this.store.dispatch(new JourneyAvailabilityStatus(true));
    }
  }

  /**
   * Triggers a store status check.
   *
   * @param {Object} payload - The payload object containing the URL and store ID.
   * @param {string} payload.url - The URL of the store.
   * @param {string} payload.storeId - The ID of the store.
   * @return {Promise<void>} A promise that resolves when the store status check is complete.
   */
  async _triggerStoreStatusCheck(payload: {
    url: string;
    storeId: string;
  }): Promise<void> {
    const isWorkerAvailable = this.storageService.get(
      StorageKeys.IS_WORKER_AVAILABLE,
    );

    if (
      typeof Worker !== "undefined" &&
      this.worker === null &&
      isWorkerAvailable === null
    ) {
      this.worker = new Worker(
        new URL("../../../store-status-check.worker", import.meta.url),
      );
      this.storageService.set(StorageKeys.IS_WORKER_AVAILABLE, "true");
      this.worker.onmessage = ({ data }) => {
        this.store.dispatch(new SegmentAvailabilityStatus(true));
        this.store.dispatch(new CampaignAvailabilityStatus(true));
        this.store.dispatch(new JourneyAvailabilityStatus(true));
        this.worker.terminate();
        this.storageService.remove(StorageKeys.IS_WORKER_AVAILABLE);
        this.worker = null;
        this.logger.log("store sync completed", data);

        this._manageStore(data);
      };

      const prefix = this.storageService.get(StorageKeys.STORE_PREFIX);
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      this.worker.postMessage({
        url: payload.url,
        token,
        storeId: payload.storeId,
        prefix,
      });
    }
  }

  @Action(CurrentSubscription)
  currentSubscription({
    patchState,
    getState,
  }: StateContext<ShopifyStoreStateModel>) {
    this.paymentServices.getCurrentStoreSubscription().then((res: IPlan) => {
      this.logger.info("current subscription: ", res);

      patchState({
        ...getState(),
        currentSubscription: res,
      });
    });
  }

  @Action(UpdateWallet)
  updateWallet({ getState, patchState }: StateContext<ShopifyStoreStateModel>) {
    this.paymentServices.getAvailableCredits().then((res: IWallet) => {
      const state = getState();
      patchState({
        ...state,
        wallet: res,
      });
      this.crispService.lowCredit(
        res?.balance <= THRESHOLD_AMOUNT ? CRISP_STATUS.YES : CRISP_STATUS.NO,
        res?.balance,
      );
    });
  }

  @Action(OnSwitchStore)
  clearOnSwitchAccount({
    patchState,
    getState,
  }: StateContext<ShopifyStoreStateModel>) {
    const currentState = {
      ...getState(),
      selectedStore: undefined,
      currency: undefined,
      currentSubscription: undefined,
      wallet: undefined,
    };
    patchState({ ...currentState });
  }
}
