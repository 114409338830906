import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";
import { TOOLTIP_POSITION } from "../../../core/enums/tooltip.enum";

const CLASS_LIST = [
  "hs-tooltip-content",
  "opacity-100",
  "visible",
  "transition-opacity",
  "inline-block",
  "z-10",
  "py-1",
  "px-2",
  "bg-gray-900",
  "text-xs",
  "font-medium",
  "text-white",
  "rounded",
  "shadow-sm",
  "fixed",
  "z-[12]",
];

@Directive({
  selector: "[tfTooltip]",
})
export class TfTooltipDirective {
  @Input() content: string;
  @Input() additionalXOffset: number = 0;
  @Input() additionalYOffset: number = 0;
  @Input() elementId: string;
  @Input() showOnOverflow: boolean = false;
  @Input() tooltipPosition: TOOLTIP_POSITION = TOOLTIP_POSITION.TOP;

  positionBasesFn = {
    [TOOLTIP_POSITION.TOP]: this.getTopPosition.bind(this),
    [TOOLTIP_POSITION.BOTTOM]: this.getBottomPosition.bind(this),
    [TOOLTIP_POSITION.LEFT]: this.getLeftPosition.bind(this),
    [TOOLTIP_POSITION.RIGHT]: this.getRightPosition.bind(this),
  };

  @HostListener("mouseenter", ["$event.target"]) onMouseEnter(e) {
    this.showOnOverflow ? this.showTooltipOnOverflow(e) : this.showTooltip(e);
  }

  @HostListener("mouseleave") onMouseLeave() {
    const element = document.getElementById(this.elementId);
    if (element) {
      element.remove();
    }
  }

  constructor(
    protected el: ElementRef,
    protected render: Renderer2,
  ) {}

  showTooltip(e) {
    const element = this.render.createElement("span");
    element.setAttribute("id", this.elementId);
    element.classList.add(...CLASS_LIST);
    const [y, x] = this.positionBasesFn[this.tooltipPosition](
      e.getBoundingClientRect(),
      element.width ? element.width : 0,
      element.height ? element.height : 0,
    );
    element.style.left = `${x + this.additionalXOffset}px`;
    element.style.top = `${y + this.additionalYOffset}px`;
    element.textContent = this.content;
    this.render.appendChild(this.el.nativeElement, element);
  }

  getTopPosition(
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
    width: number,
    height: number,
  ) {
    return [rect.y - rect.height, rect.x + (rect.width - width) / 3];
  }

  getLeftPosition(
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
    width: number,
    height: number,
  ) {
    return [rect.y + (rect.height - height) / 3, rect.x - rect.width];
  }

  getRightPosition(
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
    width: number,
    height: number,
  ) {
    return [rect.y + (rect.height - height) / 3, rect.x + rect.width];
  }

  getBottomPosition(
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
    width: number,
    height: number,
  ) {
    return [rect.y + rect.height, rect.x + (rect.width - width) / 3];
  }

  showTooltipOnOverflow(e) {
    if (this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth) {
      this.showTooltip(e);
    }
  }
}
