import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IShopifyStore } from "../interfaces/store.interface";
import { ShopifyStoreState } from "../store/user-store/user-store.state";

@Injectable({ providedIn: "root" })
export class BlockedStoreGuard  {
  @Select(ShopifyStoreState.getSelectedShopifyStore)
  selectedStore$: Observable<IShopifyStore>;
  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.selectedStore$.pipe(
      map((res: IShopifyStore) => !res?.is_disabled)
    );
  }
}
