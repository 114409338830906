import { Component, OnInit } from "@angular/core";
import Amplify from "aws-amplify";
import { environment } from "../environments/environment";
import { LoaderService } from "./core/services/loader.service";
import { CoreBackendService } from "./core/services/core.services";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Observable } from "rxjs";

import { IStaticMethods } from "preline/preline";
import { ToastService } from "./core/services/toast.service";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

declare let mixpanel: any;

@Component({
  selector: "tf-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private coreService: CoreBackendService,
    protected toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    this._listenRouteChanges();
    this.coreService.updateEnrolledStores();

    Amplify.configure({
      Auth: {
        region: environment.cognitoRegion,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.cognitoClientId,
        mandatorySignIn: false,
      },
    });
  }

  get loaderObservable(): Observable<boolean> {
    return this.loaderService.getLoaderObservable();
  }

  _listenRouteChanges() {
    this.router.events.subscribe((event) => {
      if (environment.production && event instanceof NavigationStart) {
        mixpanel.track("Link Clicked", { url: event.url });
      }
      if (event instanceof NavigationEnd) {
        if (event.url.includes("/templates/builder")) {
          this.toggleClassItems(true);
        } else {
          this.toggleClassItems(false);
        }
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      }
    });
  }

  toggleClassItems(remove: boolean) {
    const element = document.getElementById("layout-container");
    if (remove) {
      element.classList.remove("container");
    } else if (!element.classList.contains("container")) {
      element.classList.add("container");
    }
  }
}
