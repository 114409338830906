<div class="flex items-center justify-start gap-2" [formGroup]="timePickerForm">
  <input type="number"
         placeholder="HH"
         formControlName="hour"
         class="border py-3 px-4 block border-gray-200 rounded-lg text-sm
         focus:border-secondary-500 focus:ring-secondary-500 w-[80px]"
         min="0"
         max="23"
  >
  <span>:</span>
  <input type="number"
         placeholder="MM"
         formControlName="minute"
         class="border py-3 px-4 block  border-gray-200 rounded-lg text-sm
         focus:border-secondary-500 focus:ring-secondary-500 w-[80px]"
         min="0"
         max="59"
  >
</div>
