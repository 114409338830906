import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class LoaderService {
  loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}

  showLoader(): void {
    this.loader$.next(true);
  }

  stopLoader(): void {
    this.loader$.next(false);
  }

  getCurrentLoaderStatus(): boolean {
    return this.loader$.getValue();
  }

  getLoaderObservable(): Observable<boolean> {
    return this.loader$.asObservable();
  }
}
