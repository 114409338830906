import * as humps from "humps";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CaseConversion {
  toCamelCase<Type>(data: Type) {
    return humps.camelizeKeys(data);
  }
  deCamelCase<Type>(data: Type){
    return humps.decamelizeKeys(data);
  }
}
