<div [style.min-height.px]="350">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Purchase Subscription</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12" *ngIf="pricingPlans$ | async as plans; else loadingPlans">
        <p>Your current subscription plan doesn't support this feature. Upgrade your plan to proceed</p>
        <div class="row">
          <div class="col-lg-4 col-sm-12" *ngIf="plans.subscriptionPlans.length > 0">
            <tf-plan [plan]="plans.subscriptionPlans[0]"
                     (activateSubscription)="activateSubscription($event)"></tf-plan>
          </div>
          <div class="col-lg-4 col-sm-12" *ngIf="plans.subscriptionPlans.length > 0">
            <tf-plan [plan]="plans.subscriptionPlans[1]" [previousLevelPlanName]="plans.subscriptionPlans[0]?.plan_name"
                     (activateSubscription)="activateSubscription($event)"></tf-plan>
          </div>
          <div class="col-lg-4 col-sm-12" *ngIf="plans.subscriptionPlans.length > 0">
            <tf-plan [plan]="plans.subscriptionPlans[2]" [previousLevelPlanName]="plans.subscriptionPlans[1]?.plan_name"
                     (activateSubscription)="activateSubscription($event)"></tf-plan>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loadingPlans>
      <tf-section-loader [style.min-height.px]="350"></tf-section-loader>
    </ng-template>
  </div>
</div>
