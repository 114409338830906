import { ngxLoadingAnimationTypes } from "ngx-loading";

export const LOADER_CONFIG = {
  primaryColor: "#fff",
  secondaryColor: "#ffffff",
  backgroundDropColor: "rgba(0,0,0,0.5)",
  backdropBorderRadius: "3px",
};

export const GLOBAL_LOADER_CONFIG = {
  primaryColour: LOADER_CONFIG.primaryColor,
  secondaryColour: LOADER_CONFIG.secondaryColor,
  backdropBorderRadius: LOADER_CONFIG.backdropBorderRadius,
  animationType: ngxLoadingAnimationTypes.pulse,
  backdropBackgroundColour: LOADER_CONFIG.backgroundDropColor,
  fullScreenBackdrop: true,
};
