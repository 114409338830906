<div
        class="flex items-start justify-start w-full"
        *ngIf="loaderType === LoaderTypes.METRIC_LOADER"
>
    <ngx-skeleton-loader
            appearance="circle"
            animation="progress"
            [theme]="{ width: '45px', height: '45px' }"
    />
    <div class="flex flex-col w-full">
        <ngx-skeleton-loader
                appearance="line"
                animation="progress"
                [theme]="{
        'min-width': '100px',
        width: '100%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '0px'
      }"
        />
        <ngx-skeleton-loader
                appearance="line"
                animation="progress"
                [theme]="{
        'min-width': '100px',
        width: '100%',
        'border-radius': '0',
        height: '20px',
        'margin-top': '0px'
      }"
        />
    </div>
</div>

<ngx-skeleton-loader
        *ngIf="loaderType === LoaderTypes.CHART_LOADER"
        appearance="line"
        animation="progress"
        [theme]="{ width: '100%', 'min-height': '150px', height: '100%' }"
/>

<ngx-skeleton-loader
        appearance="line"
        *ngIf="loaderType === LoaderTypes.TABLE_LOADER"
        [count]="lineCount"
        animation="progress"
        [theme]="{
    width: '100%',
    height: lineHeight + 'px',
    'margin-bottom': '5px'
  }"
/>

<div class="fixed inset-0 flex w-full h-screen items-center justify-center bg-gray-50 bg-opacity-50 z-[50]"
     *ngIf="loaderType === LoaderTypes.FULL_SECTION_CIRCLE_LOADER">
    <div
            class="animate-spin inline-block w-10 h-10 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
            role="status" aria-label="loading">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="h-5 w-5 scale-[0.20] origin-center absolute"
     style="top: calc(50% - 22px);right: 10px;"
     *ngIf="loaderType === LoaderTypes.ICON_LOADER">
    <svg
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
    >
        <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
        ></circle>
        <path
                class="opacity-75"
                fill="black"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
    </svg>
</div>
