import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ITemplate } from "../interfaces/template.interface";
import { IEmailIdentity } from "../interfaces/channel.interface";

@Injectable({ providedIn: "root" })
export class EmailService {
  constructor(private httpClient: HttpClient) {}

  getEmailTemplates(
    page: number = 0,
    limit: number = 12
  ): Promise<{
    templates: Array<ITemplate>;
    count: number;
    message: string;
  }> {
    const url = `${environment.baseServiceURL}/templates?page=${
      page + 1
    }&limit=${limit}`;
    return this.httpClient
      .get<{ templates: Array<ITemplate>; count: number; message: string }>(url)
      .toPromise();
  }

  getThemedTemplates(): Promise<Array<ITemplate>> {
    const url = `${environment.baseServiceURL}/templates/base?type=2`;
    return this.httpClient.get<Array<ITemplate>>(url).toPromise();
  }

  getTemplate(templateName: string): Promise<ITemplate> {
    const url = `${environment.baseServiceURL}/templates/${templateName}`;
    return this.httpClient.get<ITemplate>(url).toPromise();
  }

  getBaseTemplates(): Promise<Array<ITemplate>> {
    const url = `${environment.baseServiceURL}/templates/base?type=1`;
    return this.httpClient.get<Array<ITemplate>>(url).toPromise();
  }

  deleteTemplate(templateId: string): Promise<any> {
    const url = `${environment.baseServiceURL}/templates/${templateId}`;
    return this.httpClient.delete(url).toPromise();
  }

  generateEmailIdentity(data: { identityName: string }): Promise<any> {
    const url = `${environment.baseServiceURL}/email/identity`;
    return this.httpClient.post(url, data).toPromise();
  }

  getEmailIdentities(): Promise<Array<IEmailIdentity>> {
    const url = `${environment.baseServiceURL}/email/identity`;
    return this.httpClient.get<Array<IEmailIdentity>>(url).toPromise();
  }

  deleteEmailIdentity(domain: string): Promise<any> {
    const url = `${environment.baseServiceURL}/email/identity`;
    return this.httpClient
      .request("delete", url, { body: { identityName: domain } })
      .toPromise();
  }

  refreshDkimAttributes(domain: string): Promise<any> {
    const url = `${environment.baseServiceURL}/email/identity/refresh`;
    return this.httpClient.post(url, { domain: domain }).toPromise();
  }

  deleteDomainDNS(domain: string): Promise<any> {
    const url = `${environment.baseServiceURL}/email/identity?domain=${domain}`;
    return this.httpClient.delete(url).toPromise();
  }

  sendEmail(payload: any): Promise<any> {
    const url = `${environment.baseServiceURL}/templates/test/email`;
    return this.httpClient.post(url, { payload }).toPromise();
  }
}
