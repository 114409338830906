<div
  class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
>
  <div
    class="flex justify-between items-center py-3 px-4"
  >
    <h3 class="font-semibold text-gray-800 text-lg">{{ config?.title }}</h3>
    <span
      class="p-2 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
      (click)="close(false)"
    >
      <i class="bi-x-lg scale-125"></i>
    </span>
  </div>
  <div class="p-4 overflow-y-auto">
    <p
      class="mt-1 text-gray-800"
      [innerHTML]="config?.bodyText"
    ></p>
  </div>
  <div
    class="flex justify-end items-center gap-x-2 py-3 px-4"
  >
    <tf-button (onClick)="close(false)" [buttonStyle]="config?.cancelButtonStyle"
               [name]="config?.cancelButton"/>
    
    <tf-button (onClick)="close(true)" [buttonStyle]="config?.successButtonStyle"
               [name]="config?.successButton"/>
  </div>
</div>

