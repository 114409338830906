import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LoaderService } from "../core/services/loader.service";
import { catchError, finalize, map } from "rxjs/operators";

const LOADING_WHITELIST = [
  "/status",
  "getEnrolledStores",
  "/segments/estimate",
  "/products?search",
  "/dashboard/",
  "/subscription",
  "/stores",
];
const LoaderStack = [];
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const isWhiteListedURL = LOADING_WHITELIST.findIndex((url) =>
      req.url.includes(url)
    );
    if (isWhiteListedURL < 0) {
      this.loaderService.showLoader();
      LoaderStack.push(1);
    }
    return next
      .handle(req)
      .pipe(
        catchError((err) => {
          return err;
        }),
        finalize(() => this.stopLoader())
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          return evt;
        })
      );
  }

  stopLoader() {
    LoaderStack.pop();
    if (LoaderStack.length === 0) {
      this.loaderService.stopLoader();
    }
  }
}
