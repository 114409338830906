import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { ShopifyStoreState } from "../../core/store/user-store/user-store.state";
import { Observable } from "rxjs";
import {
  IEnrolledStore,
  IShopifyStore,
} from "../../core/interfaces/store.interface";
import { UserStoreState } from "../../core/store/user/user.state";
import { IUser } from "../../core/interfaces/user.interface";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../core/services/auth.service";
import { ChannelService } from "../../core/services/channel.service";
import { EmailService } from "../../core/services/email.service";
import { NGXLogger } from "ngx-logger";
import {
  IChannel,
  IEmailIdentity,
} from "../../core/interfaces/channel.interface";
import {
  updateChannelStatus,
  UpdateVerifiedEmailIdentity,
} from "../../core/store/identities/identities.action";
import { ModalService } from "../../core/services/modal.service";
import { SwitchAccountComponent } from "../../shared/modal/switch-account/switch-account.component";
import { ToastService } from "../../core/services/toast.service";
import { TOOLTIP_POSITION } from "../../core/enums/tooltip.enum";
import { Roles } from "../../core/enums/role.enum";
import {
  BADGE_STYLES,
  BUTTON_STYLES_TYPE,
} from "../../core/constants/button.constant";

declare let $crisp: any;

@Component({
  selector: "tf-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  element: any;
  protected readonly TOOLTIP_POSITION = TOOLTIP_POSITION;
  protected readonly Roles = Roles;
  protected readonly BUTTON_STYLES_TYPE = BUTTON_STYLES_TYPE;

  @Select(ShopifyStoreState.getSelectedShopifyStore)
  selectedShopifyStore$: Observable<IShopifyStore>;

  @Select(UserStoreState.getCurrentUser) currentUser$: Observable<IUser>;
  @Select(ShopifyStoreState.getSelectedShopifyStore)
  selectedStore$: Observable<IShopifyStore>;

  @ViewChild("profile") profile: ElementRef;

  @HostListener("window:click", ["$event"]) onWindowClick(_: Event) {
    if (!this.profile.nativeElement.classList.contains("hidden")) {
      this.profile.nativeElement.classList.add("hidden");
    }
  }

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private store: Store,
    private channelService: ChannelService,
    private emailService: EmailService,
    private logger: NGXLogger,
    private tfModalService: ModalService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.element = document.documentElement;
    this.getChannelDetail();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout().then();
    $crisp.push(["do", "session:reset"]);
    this.router
      .navigate(["/account/login"], { queryParams: { reload: true } })
      .then();
  }

  async switchAccount(): Promise<void> {
    try {
      this.tfModalService.open(SwitchAccountComponent, {
        classes: ["md:max-w-2xl md:w-full"],
      });
      const result: { success: boolean; currentStore: IEnrolledStore } =
        await this.tfModalService.result();
      if (result.success) {
        this.toastService.showToast({
          message: `You are acting as ${result.currentStore.storeUrl} now`,
          icon: "bx bx-shopping-bag",
        });
      }
    } catch (e) {
      this.logger.error("failed to switch store: ", e);
    }
  }

  getChannelDetail(): void {
    this.channelService.getAllChannels().then((res: Array<IChannel>) => {
      this.store.dispatch(new updateChannelStatus(res));
    });

    this.emailService
      .getEmailIdentities()
      .then((res: Array<IEmailIdentity>) => {
        try {
          const isVerified = res.filter(
            (item: IEmailIdentity) => item.VerifiedForSendingStatus,
          )[0];
          this.store.dispatch(
            new UpdateVerifiedEmailIdentity(
              isVerified.VerifiedForSendingStatus,
            ),
          );
        } catch (e) {
          this.store.dispatch(new UpdateVerifiedEmailIdentity(false));
        }
      });
  }

  openPreview(e) {
    this.logger.debug(this.profile);
    e.stopPropagation();
    this.profile.nativeElement.classList.toggle("hidden");
  }

  protected readonly BADGE_STYLES = BADGE_STYLES;
}
