<nav class="flex items-center gap-x-1" *ngIf="totalPages > 1">
  <button type="button"
          class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          (click)="changePage(currentPage - 1)"
          [ngClass]="{ disabled: currentPage <= 0 }">
    <svg class="flex-shrink-0 w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="m15 18-6-6 6-6"/>
    </svg>
    <span aria-hidden="true" class="sr-only">Previous</span>
  </button>
  <div class="flex items-center gap-x-1">
    <button type="button" *ngFor="let page of somePageNumbers"
            class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-transparent text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
            aria-current="page"
            (click)="changePage(page - 1)"
            [ngClass]="{'border-secondary-600 text-secondary-600 bg-secondary-50': (currentPage + 1) === page}"
    >{{ page }}
    </button>
    <div class="hs-tooltip inline-block" *ngIf="totalPages > 5">
      <button type="button"
              class="hs-tooltip-toggle group min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-400 hover:text-blue-600 p-2 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
              (click)="changePage(currentPage + 4)"
      >
        <span class="group-hover:hidden text-xs">•••</span>
        <svg class="group-hover:block hidden flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24"
             height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
             stroke-linejoin="round">
          <path d="m6 17 5-5-5-5"/>
          <path d="m13 17 5-5-5-5"/>
        </svg>
        <span
          class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm"
          role="tooltip">
                  Next 4 pages
                </span>
      </button>
    </div>
    <button type="button"
            class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-transparent text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
            [ngClass]="{'border-secondary-600 text-secondary-600 bg-secondary-50': (currentPage + 1) === totalPages}"
            (click)="changePage(totalPages - 1)">
      {{ totalPages }}
    </button>
  
  </div>
  <button type="button"
          class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          (click)="changePage(currentPage + 1)"
          [ngClass]="{ disabled: currentPage + 1 >= totalPages }"
          *ngIf="currentPage < totalPages - 1"
  >
    <span aria-hidden="true" class="sr-only">Next</span>
    <svg class="flex-shrink-0 w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="m9 18 6-6-6-6"/>
    </svg>
  </button>
</nav>
