import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { RadioSize } from "../../../core/constants/button.constant";
import { TfElementSize } from "../../../core/enums/element-size.enum";

@Component({
  selector: "tf-radio",
  templateUrl: "./tf-radio.component.html",
  styleUrls: ["./tf-radio.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TfRadioComponent,
      multi: true,
    },
  ],
})
export class TfRadioComponent implements ControlValueAccessor {
  protected readonly RadioSize = RadioSize;
  @Input() elementId!: string;
  @Input() group!: string;
  @Input() label!: string;
  @Input() value?: string | boolean = "";
  @Input() icon?: string;
  @Input() additionalClasses!: string;
  @Input() size?: TfElementSize = TfElementSize.MD;
  isDisabled?: boolean;
  controlValue: string = "";
  onChangeFn!: Function;
  onTouchedFn!: Function;

  updateControl() {
    this.onChangeFn(this.value);
  }

  writeValue(obj: string) {
    this.controlValue = obj;
  }

  registerOnChange(fn: Function) {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
