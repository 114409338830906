import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { IUser } from "../interfaces/user.interface";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: IUser;
  static cognitoUser: any;

  constructor() {}

  /**
   * Returns the current user
   */
  public async currentUser(): Promise<any> {
    return await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  async login(email: string, password: string): Promise<CognitoUser | null> {
    return await Auth.signIn(email, password);
  }

  async doRememberDevice() {
    return await Auth.rememberDevice();
  }

  async resendConfirmation(email: string) {
    return await Auth.resendSignUp(email);
  }

  async confirmUser(email: string, code: string) {
    await Auth.confirmSignUp(email, code);
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  async register(
    email: string,
    username: string,
    password: string,
    name: string,
  ) {
    let role = 1;
    return await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        name,
        "custom:role": role.toString(),
        "custom:companyId": "Unnamed",
      },
    });
  }

  async confirmSignup(email: string, code: string) {
    return await Auth.confirmSignUp(email, code);
  }

  /**
   * Reset password
   * @param email email
   */
  async resetPassword(email: string) {
    return await Auth.forgotPassword(email);
  }

  async resetConfirmPassword(email: string, code: string, password: string) {
    return await Auth.forgotPasswordSubmit(email, code, password);
  }

  async setNewPassword(password: string) {
    return await Auth.completeNewPassword(
      AuthenticationService.cognitoUser,
      password,
      {},
    );
  }

  /**
   * Logout the user
   */
  async logout() {
    // logout the user
    localStorage.clear();
    await Auth.signOut();
  }

  public async getAuthToken() {
    return await Auth.currentSession();
  }

  async refreshToken(): Promise<boolean> {
    const currentAuthUser = await this.currentUser();
    const token = await this.getAuthToken();

    return new Promise((resolve, reject) => {
      currentAuthUser.refreshSession(
        token.getRefreshToken(),
        async (err, _) => {
          if (err) {
            console.error(err);
            await this.logout();
            reject(false);
          } else {
            resolve(true);
            console.log("session has been refreshed");
          }
        },
      );
    });
  }
}
