import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import _ from "lodash";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

const DAY_MS = 60 * 60 * 24 * 1000;
const MAX_CALENDAR_HEIGHT = 362;
const MAX_CALENDAR_WIDTH = 320;
const MAX_RANGER_CALENDAR_WIDTH = 650;

@Component({
  selector: "tf-calendar",
  templateUrl: "./tf-calendar.component.html",
  styleUrls: ["./tf-calendar.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TfCalendarComponent,
      multi: true,
    },
  ],
})
export class TfCalendarComponent implements ControlValueAccessor, OnInit {
  dates: Array<Array<Date>>;
  days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  @Input() isRangePicker: boolean = false;

  yearList: Array<number> = _.range(2023, new Date().getFullYear() + 10);

  date = new Date();
  @Output() selected = new EventEmitter();
  selectedDate: Date;
  toggle: boolean = false;

  @ViewChild("calendar") calendar!: ElementRef;

  monthControl: FormControl<number> = new FormControl<number>(
    this.date.getMonth(),
  );
  yearControl: FormControl<number> = new FormControl<number>(
    this.date.getFullYear(),
  );

  // second calendar
  secondDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate(),
  );
  selectedSecondDate: Date;
  secondCalendarDates: Array<Array<Date>>;
  secondMonthControl: FormControl<number> = new FormControl(
    this.secondDate.getMonth(),
  );
  secondYearControl: FormControl<number> = new FormControl(
    this.secondDate.getFullYear(),
  );

  onChangeFn!: Function;
  onTouchedFn!: Function;

  @HostListener("window:click", ["$event.target"]) onClick(event: Event) {
    if (!this.calendar.nativeElement.contains(event) && this.toggle) {
      this.toggle = !this.toggle;
      document.body.removeChild(this.calendar.nativeElement);
    }
  }

  constructor(private logger: NGXLogger) {
    this.dates = this.getCalendarDays(this.date);
    this.secondCalendarDates = this.getCalendarDays(this.secondDate);
  }

  ngOnInit() {
    this.listenMonthChange();
    this.listenYearChange();
    if (this.isRangePicker) {
      this.listenSecondMonthChange();
      this.listenSecondYearChange();
    }
  }

  writeValue(obj: Date | Date[] | null): void {
    this.onTouchedFn && this.onTouchedFn(true);
    this.logger.debug("control value: ", obj);
    if (!this.isRangePicker && obj) {
      this.selectedDate = obj as Date;
      this.yearControl.setValue((obj as Date).getFullYear());
      this.monthControl.setValue((obj as Date).getMonth());
      this.date = obj as Date;
    } else if (obj && (obj as Date[]).length === 2) {
      this.selectedDate = obj[0];
      this.yearControl.setValue(obj[0].getFullYear());
      this.monthControl.setValue(obj[0].getMonth());
      this.date = obj[0] as Date;

      this.selectedSecondDate = obj[1];
      this.secondYearControl.setValue(obj[1].getFullYear());
      this.secondMonthControl.setValue(obj[1].getMonth());
      this.secondDate = obj[1] as Date;
    }
  }

  registerOnChange(fn: Function): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.yearControl.disable();
      this.monthControl.disable();
      this.secondYearControl.disable();
      this.secondMonthControl.disable();
    }
  }

  setMonth(inc: number) {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    this.yearControl.setValue(this.date.getFullYear(), { emitEvent: false });
    this.monthControl.setValue(this.date.getMonth(), { emitEvent: false });
    this.dates = this.getCalendarDays(this.date);
    this.logger.debug("***", this.dates, this.date, inc);
  }

  setSecondMonth(inc: number) {
    const [year, month] = [
      this.secondDate.getFullYear(),
      this.secondDate.getMonth(),
    ];
    this.secondDate = new Date(year, month + inc, 1);
    this.secondYearControl.setValue(this.secondDate.getFullYear(), {
      emitEvent: false,
    });
    this.secondMonthControl.setValue(this.secondDate.getMonth(), {
      emitEvent: false,
    });
    this.secondCalendarDates = this.getCalendarDays(this.secondDate);
    this.logger.debug(this.dates);
  }

  private getCalendarDays(date = new Date()) {
    const calendarStartTime = this.getCalendarStartDay(date).getTime();

    const ranges = this.range(0, 41).map(
      (num) => new Date(calendarStartTime + DAY_MS * num),
    );
    return _.chunk(ranges, 7);
  }

  private getCalendarStartDay(date = new Date()) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map((num) => new Date(firstDayOfMonth - DAY_MS * num))
      .find((dt) => dt.getDay() === 0);
  }

  private range(start: number, end: number, length: number = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i);
  }

  updateSelectedDate(date: Date) {
    const constructedDate = this.getConstructedDate(
      date.getDate(),
      parseInt(this.monthControl.value.toString(), 10) + 1,
      this.yearControl.value,
    );
    this.logger.debug(constructedDate);
    if (this.onChangeFn && !this.isRangePicker) {
      this.selectedDate = constructedDate;
      this.onChangeFn(constructedDate);
    } else if (this.onChangeFn && this.isRangePicker) {
      if (this.selectedDate && this.selectedSecondDate) {
        this.selectedDate = null;
        this.selectedSecondDate = null;
      } else if (this.selectedDate && !this.selectedSecondDate) {
        this.selectedSecondDate = constructedDate;
        this.onChangeFn([this.selectedDate, this.selectedSecondDate]);
      } else if (!this.selectedDate) {
        this.selectedDate = constructedDate;
      }
    }
  }

  updateSecondDate(date: Date) {
    const constructedDate = this.getConstructedDate(
      date.getDate(),
      parseInt(this.secondMonthControl.value.toString(), 10) + 1,
      this.secondYearControl.value,
    );
    this.logger.debug(constructedDate);
    if (this.onChangeFn) {
      if (this.selectedDate && this.selectedSecondDate) {
        this.selectedDate = null;
        this.selectedSecondDate = null;
      } else if (this.selectedDate && !this.selectedSecondDate) {
        this.selectedSecondDate = constructedDate;
        this.onChangeFn([this.selectedDate, this.selectedSecondDate]);
      } else if (!this.selectedDate) {
        this.selectedDate = constructedDate;
      }
    }
  }

  setRangePickerValue(date: Date) {
    this.logger.debug(date, this.selectedDate);
    if (this.selectedDate && this.selectedSecondDate) {
      this.selectedSecondDate = null;
      this.selectedDate = null;
    }

    if (this.selectedDate && date.getTime() > this.selectedDate.getTime()) {
      this.selectedSecondDate = date;
      if (this.onChangeFn) {
        this.onChangeFn([this.selectedDate, this.selectedSecondDate]);
      }
    } else {
      this.selectedDate = date;
    }
  }

  setPosition(event: MouseEvent) {
    document.body.appendChild(this.calendar.nativeElement);
    event.stopPropagation();
    const rect = (event.target as any).getBoundingClientRect();

    const canAlignBottom =
      window.innerHeight - (rect.y + rect.height) > MAX_CALENDAR_HEIGHT + 32;

    const canAlignRight =
      window.innerWidth - (rect.x + rect.width) >
      (this.isRangePicker ? MAX_RANGER_CALENDAR_WIDTH : MAX_CALENDAR_WIDTH);
    this.logger.debug("Can align bottom", canAlignBottom, rect, canAlignRight);

    if (!canAlignBottom) {
      this.calendar.nativeElement.style.top = `${
        rect.y - rect.height - MAX_CALENDAR_HEIGHT
      }px`;
    } else {
      this.calendar.nativeElement.style.top = `${rect.y + rect.height}px`;
    }

    if (canAlignRight) {
      this.calendar.nativeElement.style.left = `${rect.x}px`;
    } else {
      this.calendar.nativeElement.style.left = `${rect.x + rect.width - (this.isRangePicker ? MAX_RANGER_CALENDAR_WIDTH : MAX_CALENDAR_WIDTH)}px`;
    }
  }

  listenMonthChange() {
    this.monthControl.valueChanges.subscribe((_) => this._handleFirstDate());
  }

  listenYearChange() {
    this.yearControl.valueChanges.subscribe((_) => this._handleFirstDate());
  }

  listenSecondYearChange() {
    this.secondYearControl.valueChanges.subscribe((_) =>
      this._handleSecondDate(),
    );
  }

  listenSecondMonthChange() {
    this.secondMonthControl.valueChanges.subscribe((_) =>
      this._handleSecondDate(),
    );
  }

  _handleFirstDate() {
    const date = this.getConstructedDate(
      this.date.getDate(),
      parseInt(this.monthControl.value.toString(), 10) + 1,
      this.yearControl.value,
    );
    this.date = date;
    this.logger.debug("***listenMonthChange", date);
    this.dates = this.getCalendarDays(date);
    this.logger.debug("***dates", this.dates, this.monthControl.value);
  }

  _handleSecondDate() {
    const date = this.getConstructedDate(
      this.secondDate.getDate(),
      parseInt(this.secondMonthControl.value.toString(), 10) + 1,
      this.secondYearControl.value,
    );
    this.secondDate = date;
    this.logger.debug("***listenMonthChange", date);
    this.secondCalendarDates = this.getCalendarDays(date);
  }

  getConstructedDate(date: number, month: number, year: number) {
    return new Date(`${year}-${month}-${date}`);
  }
}
