<div
  class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
>
  <div class="flex items-center justify-between pt-3 px-4">
    <h5 class="text-lg font-semibold text-gray-800">Get more credits</h5>
    <span
      class="p-2 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
      (click)="close()"
    >
      <i class="bi-x-lg scale-125"></i>
    </span>
  </div>
  <div class="p-4 overflow-y-auto">
    <label class="text-sm font-medium">Select Channel to add credit</label>
    <div class="flex items-center justify-start gap-4 my-4">
      <tf-radio
        [formControl]="channel"
        value="EMAIL"
        label="Email"
        icon="bi bi-envelope"
        elementId="add-credits-email"
      />
      <tf-radio
        [formControl]="channel"
        value="SMS"
        label="SMS"
        icon="bi bi-chat-left"
        elementId="add-credits-sms"
      />
      <tf-radio
        [formControl]="channel"
        value="WHATSAPP"
        label="WhatsApp"
        icon="bi bi-whatsapp"
        elementId="add-credits-whatsapp"
      />
    </div>
    <div class="mb-3">
      <tf-input
        [formControl]="price"
        placeholder="Enter Amount"
        type="text"
        icon="bi bi-currency-dollar"
      />
      <tf-field-error-message [errorControl]="price"></tf-field-error-message>
    </div>

    <div>
      <ul class="flex flex-col divide-y divide-gray-200">
        <ng-container *ngIf="channel.value === 'EMAIL'">
          <h5 class="text-sm font-medium">You will get</h5>
          <li
            class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium text-gray-800"
          >
            <div class="flex items-center justify-between w-full relative">
              <span *ngIf="!isCountLoading" style="font-size: larger">
                {{ unitPrice.emails * 1000 | number }} Emails Credits
              </span>
              <tf-section-loader
                [loaderType]="LoaderTypes.ICON_LOADER"
                *ngIf="isCountLoading"
              />
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="flex items-center justify-end modal-footer p-4 pt-0">
    <tf-button
      additionalStyle="relative"
      (onClick)="checkout()"
      name="Pay Now"
      [showLoader]="isLoading"
      [adjustSpaceForLoader]="true"
    />
  </div>
</div>
