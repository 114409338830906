import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IServiceFile } from "../interfaces/folder.interface";
import { CommonUtilService } from "./common.service";

@Injectable({ providedIn: "root" })
export class DataService {
  constructor(
    private httpClient: HttpClient,
    private commonUtilService: CommonUtilService
  ) {}

  async uploadFiles(file: File, folder: string, name: string): Promise<any> {
    const url = `${
      environment.baseServiceURL
    }/assets/${this.commonUtilService.getActiveCustomerId()}/${folder}/${name}`;

    return this.httpClient.put(url, file).toPromise();
  }

  async getUserFiles(folder: string = ""): Promise<Array<IServiceFile>> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/media?subfolder=${folder}`;

    return this.httpClient.get<Array<IServiceFile>>(url).toPromise();
  }

  async deleteFile(fileId: string): Promise<any> {
    const url = `${environment.baseServiceURL}/media/delete`;
    const body = { key: fileId };
    return this.httpClient.post(url, body).toPromise();
  }

  async rename(body: { source: string; name: string }): Promise<any> {
    const url = `${environment.baseServiceURL}/media/rename`;
    return this.httpClient.post(url, body).toPromise();
  }
}
