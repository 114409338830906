<div class="user-orders p-4">
  <div class="flex items-start justify-end">
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="close()"
    ></button>
  </div>
  <div class="row py-2">
    <div class="flex items-center justify-between">
      <div class="flex flex-column">
        <span class="h3 text-primary">
          {{ endpoint.firstname }} {{ endpoint.lastname }}
        </span>
        <span class="text-muted">{{ endpoint.email }}</span>
      </div>
      <div class="flex flex-column align-items-end">
        <span class="h3 text-primary">
          {{ endpoint.orderscount }}
        </span>
        <span class="text-muted">Total Orders</span>
      </div>
    </div>
  </div>
  <div class="row mt-4 scr">
    <div class="p-2" *ngFor="let order of orders">
      <div class="flex items-center justify-between">
        <div class="flex flex-column">
          <a
            class="h5 text-decoration-underline"
            [href]="order.shopUrl"
            target="_blank"
          >
            {{ order?.name }}
          </a>
          <span class="text-muted">{{
            order?.createdAt | date : "mediumDate"
            }}</span>
        </div>
        <div class="flex flex-column align-items-end">
        <span class="h5">
          {{ order?.currentTotalPrice | currency : (currency$ | async) : "symbol" }}
        </span>
          <span class="text-muted">Total Price</span>
        </div>
      </div>
    </div>
  </div>
</div>
