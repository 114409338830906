import { Component, Input } from "@angular/core";
import { ModalService } from "../../../core/services/modal.service";
import { BUTTON_STYLES_TYPE } from "../../../core/constants/button.constant";

@Component({
  selector: "tf-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent {
  protected readonly BUTTON_STYLES_TYPE = BUTTON_STYLES_TYPE;
  @Input() config: {
    title: string;
    bodyText: string;
    successButton: string;
    cancelButton: string;
    successButtonStyle?: string;
    cancelButtonStyle?: string;
  } = {
    title: "Confirmation",
    bodyText: "",
    successButton: "Delete",
    cancelButton: "Cancel",
    successButtonStyle: BUTTON_STYLES_TYPE.SECONDARY_SMALL,
    cancelButtonStyle: BUTTON_STYLES_TYPE.PRIMARY_GHOST_SMALL
  };

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.config.successButtonStyle = this.config.successButtonStyle || BUTTON_STYLES_TYPE.SECONDARY_SMALL
    this.config.cancelButtonStyle = this.config.cancelButtonStyle || BUTTON_STYLES_TYPE.PRIMARY_GHOST_SMALL
  }

  close(choice?: boolean) {
    this.modalService.close(choice);
  }
}
