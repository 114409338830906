import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout.component";
import { FooterComponent } from "./footer/footer.component";
import { SharedModule } from "../shared/shared.module";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CoreElementsModule } from "../modules/core-elements/core-elements.module";

@NgModule({
  declarations: [LayoutComponent, FooterComponent, SidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CoreElementsModule,
    NgOptimizedImage,
  ],
})
export class LayoutsModule {}
