import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IAttribute, ISegment } from "../../interfaces/segment.interface";
import {
  AddSegmentAttributeList,
  AddSegmentList,
  onSwitchStoreSegmentList,
  UpdateSegmentCount,
} from "./segment.action";

export class SegmentStateModel {
  segments: Array<ISegment>;
  attributeList: Array<IAttribute>;
  segmentMap: { [key in string]: ISegment };
  segmentMetrics: {
    count: number;
    revenue: number;
  };
}

@State<SegmentStateModel>({
  name: "segment",
  defaults: {
    segments: [],
    attributeList: [],
    segmentMap: {},
    segmentMetrics: {
      count: 0,
      revenue: null,
    },
  },
})
@Injectable({
  providedIn: "root",
})
export class SegmentState {
  @Selector()
  static getSegments(state: SegmentStateModel) {
    return state.segments;
  }

  @Selector()
  static getSegmentMap(state: SegmentStateModel) {
    return state.segmentMap;
  }

  @Selector()
  static getSegmentAttributeList(state: SegmentStateModel) {
    return state.attributeList;
  }

  @Selector()
  static getSegmentMetrics(state: SegmentStateModel) {
    return state.segmentMetrics;
  }

  @Action(AddSegmentList)
  addSegmentList(
    { getState, patchState }: StateContext<SegmentStateModel>,
    { payload }: AddSegmentList
  ) {
    const state = getState();
    const segmentMap = {};
    payload.forEach((res: ISegment) => {
      segmentMap[res.segmentId] = res;
    });

    const segmentMetrics = this.calculateSegmentMetrics(
      Object.values(segmentMap),
      { ...state.segmentMetrics }
    );
    patchState({
      ...state,
      segments: Object.values(segmentMap),
      segmentMap,
      segmentMetrics,
    });
  }

  @Action(AddSegmentAttributeList)
  addSegmentAttributeList(
    { getState, patchState }: StateContext<SegmentStateModel>,
    { payload }: AddSegmentAttributeList
  ) {
    const state = getState();
    patchState({ ...state, attributeList: payload });
  }

  calculateSegmentMetrics(segments: Array<ISegment>, segmentMetrics: any) {
    segmentMetrics.revenue = segments.reduce((revenue: any, segment: any) => {
      if (
        segment?.revenue &&
        segment.Name !== "base-segments-all-audience" &&
        segment.revenue !== "-1"
      ) {
        revenue = revenue + (parseFloat(segment?.revenue) ?? 0);
      }
      return revenue;
    }, 0);

    return segmentMetrics;
  }

  @Action(UpdateSegmentCount)
  updateSegmentCount(
    { getState, patchState }: StateContext<SegmentStateModel>,
    payload: UpdateSegmentCount
  ) {
    const state = getState();
    const metrics = { ...state.segmentMetrics };
    metrics.count = payload.count;
    patchState({ ...state, segmentMetrics: metrics });
  }

  @Action(onSwitchStoreSegmentList)
  onSwitchStoreSegmentList({
    getState,
    patchState,
  }: StateContext<SegmentStateModel>) {
    const state = getState();
    patchState({
      ...state,
      segments: [],
      segmentMap: {},
      segmentMetrics: {
        count: 0,
        revenue: null,
      },
    });
  }
}
