import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { LoaderTypes } from "../../core/enums/loader-type.enum";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgIf } from "@angular/common";

@Component({
  selector: "tf-section-loader",
  templateUrl: "./section-loader.component.html",
  styleUrls: ["./section-loader.component.scss"],
  standalone: true,
  imports: [NgxSkeletonLoaderModule, NgIf],
})
export class SectionLoaderComponent implements AfterViewInit, OnDestroy {
  protected readonly LoaderTypes = LoaderTypes;
  @Input() loaderType: LoaderTypes;
  @Input() lineCount: number = 5;
  @Input() lineHeight: number = 80;

  ngAfterViewInit() {
    if (this.loaderType === LoaderTypes.FULL_SECTION_CIRCLE_LOADER) {
      document.body.style.overflow = "hidden";
    }
  }

  ngOnDestroy() {
    document.body.style.overflow = "auto";
  }
}
