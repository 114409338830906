<div class="flex flex-column justify-content-center">
  <div>
    <tf-choose-channel
      [channels]="staticData?.channels"
      (setChannels)="selectChannels($event)"
      (skip)="skip('category')"
      *ngIf="activeIndex === 'channels'"
    ></tf-choose-channel>
    <tf-create-email-identity
      (createdEmailIdentity)="skip('category')"
      (skip)="skip('category')"
      *ngIf="activeIndex === 'identity'"
    ></tf-create-email-identity>
    <tf-choose-category
      [categories]="staticData?.categories"
      (setCategory)="selectCategory($event)"
      (skip)="skip('goal')"
      *ngIf="activeIndex === 'category'"
    ></tf-choose-category>
    <tf-choose-goals
      [goals]="staticData?.goals"
      (setGoals)="selectGoals($event)"
      (skip)="skip('reference')"
      *ngIf="activeIndex === 'goal'"
    ></tf-choose-goals>
    <tf-choose-reference
      [references]="staticData?.references"
      (setReference)="selectReference($event)"
      (skip)="skip()"
      *ngIf="activeIndex === 'reference'"
    ></tf-choose-reference>
  </div>

  <!-- end container -->
</div>
<!-- end page -->
