<div
  class="border h-full rounded-lg p-4 relative flex flex-col mb-[200px]"
>
  <h1 class="text-lg font-semibold text-center mb-4">
    {{ plan?.plan_name }}
  </h1>
  <div class="absolute top-[45px] w-full left-0 flex items-center justify-center">
    <tf-badge *ngIf="isCurrentSubscription">Your Subscription</tf-badge>
  </div>
  <p class="text-2xl font-light text-center my-4" *ngIf="plan?.price === 0">
    Free forever
  </p>
  <p class="text-2xl font-light text-center my-4" *ngIf="plan?.price > 0">
    {{ plan?.price | currency }}/mo
  </p>
  
  <p class="text-md my-2 text-center" [ngClass]="{'mb-6': plan.subscription_key === 'kick_off'}">
    {{ plan?.plan_description }}
  </p>
  <p class="text-md my-4 text-center block" *ngIf="previousLevelPlanName">
    Everything in {{ previousLevelPlanName }} +
  </p>
  
  <ul class="space-y-3 text-sm">
    <li class="flex space-x-3" *ngIf="plan.subscription_key === 'kick_off'">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          Unlimited Segmentation
        </span>
    </li>
    
    <li class="flex space-x-3" *ngIf="plan?.journey_quota === -1">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          Unlimited Journeys
        </span>
    </li>
    
    <li class="flex space-x-3" *ngIf="plan?.journey_quota > 0">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          {{ plan?.journey_quota }} Journeys
        </span>
    </li>
    
    <li class="flex space-x-3">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          Available Channels: {{ channels }}
        </span>
    </li>
    <li class="flex space-x-3">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          Pre-built {{ channels }} templates
        </span>
    </li>
    
    <li class="flex space-x-3" *ngIf="plan.subscription_key === 'kick_off'">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          Drag & Drop Email builder
        </span>
    </li>
    
    <li class="flex space-x-3" *ngIf="plan.subscription_key === 'kick_off'">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 "> 24x7 Support </span>
    </li>
    
    <li class="flex space-x-3" *ngIf="plan?.customer_success">
        <span
          class="h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full bg-secondary-100 text-secondary-600 text-secondary-600"
        >
          <svg
            class="flex-shrink-0 h-3.5 w-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"/>
          </svg>
        </span>
      <span class="text-gray-800 ">
          Dedicated success managers
        </span>
    </li>
  </ul>
  
  <div class="absolute bottom-[16px] left-[16px] right-[16px] mt-8">
    <p class="font-medium text-sm text-center mb-2">
      After {{ (this.plan.free_credits) | number: '1.0-0' }}
      emails: {{ (1 / this.plan.rate_card.email_price) | number: '1.0-0' }} emails per $1
    </p>
    <div class="flex items-center justify-center">
      <tf-button
        *ngIf="(plan.subscription_key !== 'kickoff' || !showPurchaseButton) && (!isCurrentSubscription)"
        name=" {{ buttonName ? buttonName : 'Activate Subscription' }}"
        (onClick)="subscribePlatform()"
      />
      <tf-button
        *ngIf="(plan.subscription_key !== 'kickoff' && !showPurchaseButton) && (isCurrentSubscription)"
        name="Cancel Subscription"
        (onClick)="cancelPlatformSubscription()"
        [buttonStyle]="BUTTON_STYLES_TYPE.DANGER"
      />
      <tf-button
        *ngIf="plan.subscription_key === 'kickoff' && !showPurchaseButton && isCurrentSubscription"
        [disabled]="true"
        name="Default Subscription"
      />
      <tf-button
        *ngIf="plan.subscription_key === 'kickoff' && showPurchaseButton && !isCurrentSubscription"
        name=" {{ buttonName ? buttonName : 'Default Subscription' }}"
        (onClick)="subscribePlatform()"
      />
    </div>
  </div>
</div>
