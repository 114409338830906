import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SwitchAccountComponent } from "./modal/switch-account/switch-account.component";
import { ConfirmationModalComponent } from "./modal/confirmation-modal/confirmation-modal.component";
import { FieldErrorMessageComponent } from "./field-error-message/field-error-message.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { CheckoutComponent } from "./modal/checkout/checkout.component";
import { AlertComponent } from "./alert/alert.component";
import { PreviewComponent } from "./preview/preview.component";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { UserOrdersComponent } from "./user-orders/user-orders.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { environment } from "../../environments/environment";
import { NgxLoadingModule } from "ngx-loading";
import { GLOBAL_LOADER_CONFIG } from "../core/constants/loader.constant";
import { SubscriptionComponent } from "./modal/subscription/subscription.component";
import { PlanComponent } from "./plan/plan.component";
import { MediaUploadComponent } from "./modal/media-upload/media-upload.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { DROPZONE_CONFIG, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { CoreElementsModule } from "../modules/core-elements/core-elements.module";
import { SectionLoaderComponent } from "./section-loader/section-loader.component";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: `${environment.baseServiceURL}/assets/`,
  maxFilesize: 10,
  acceptedFiles: "image/*",
  uploadMultiple: true,
  method: "put",
};

@NgModule({
  declarations: [
    SwitchAccountComponent,
    ConfirmationModalComponent,
    FieldErrorMessageComponent,
    CheckoutComponent,
    AlertComponent,
    PreviewComponent,
    UserOrdersComponent,
    PaginationComponent,
    SubscriptionComponent,
    PlanComponent,
    MediaUploadComponent,
    PageHeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LoggerModule.forRoot({
      level: environment.production
        ? NgxLoggerLevel.FATAL
        : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
      enableSourceMaps: !environment.production,
    }),
    NgxLoadingModule.forRoot(GLOBAL_LOADER_CONFIG),
    NgxDropzoneModule,
    CoreElementsModule,
    SectionLoaderComponent,
  ],
  exports: [
    FieldErrorMessageComponent,
    CheckoutComponent,
    AlertComponent,
    PreviewComponent,
    LoggerModule,
    PaginationComponent,
    NgxLoadingModule,
    PlanComponent,
    MediaUploadComponent,
    PageHeaderComponent,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
})
export class SharedModule {}
