import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CommonUtilService } from "./common.service";

import {
  ICreditService,
  IUnitPriceService,
  IWallet,
} from "../interfaces/credit.interface";
import { Observable, of } from "rxjs";
import { ICPlan, IPlan } from "../interfaces/subscriptionPlans.interface";
import { catchError, map } from "rxjs/operators";
import { DEFAULT_SUBSCRIPTION_PLAN } from "../constants/application.constant";
import { CRISP_STATUS } from "../enums/message-type.enum";
import { CrispService } from "./crisp.service";
import { CaseConversion } from "../utils/case-conversion.util";
import { ChannelConstants } from "../constants/channel.constants";

@Injectable({ providedIn: "root" })
export class PaymentService {
  constructor(
    private httpClient: HttpClient,
    private commonUtilService: CommonUtilService,
    private crispService: CrispService,
    private caseConversionUtil: CaseConversion
  ) {}

  addCredits(quantity: number, channel: string): any {
    let url = `${environment.baseServiceURL}/payments/credits`;
    if (channel === ChannelConstants.WHATSAPP) {
      url = `${environment.segmentServiceBaseURL}/whatsapp/credits`;
    }
    return this.httpClient.post(url, {
      quantity: quantity,
    });
  }

  addSMSCredits(quantity: number, channel: string): any {
    let url = `${environment.baseServiceURL}/payments/credits`;
    if (channel === ChannelConstants.SMS) {
      url = `${environment.segmentServiceBaseURL}/sms/credits`;
    }
    return this.httpClient.post(url, {
      quantity: quantity,
    });
  }

  getAvailableCredits(): Promise<IWallet> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/wallet`;

    return this.httpClient.get<IWallet>(url).toPromise();
  }

  getUnitRate(unit: number): Observable<IUnitPriceService> {
    const url = `${environment.baseServiceURL}/payments/unit?credits=${unit}`;
    return this.httpClient.get<IUnitPriceService>(url);
  }

  createCustomSubscription(payload: ICPlan) {
    let url = `${environment.paymentBaseUrl}/subscription/custom`;
    return this.httpClient.post(url, {
      payload: this.caseConversionUtil.deCamelCase(payload),
    });
  }

  platformSubscription(
    planId: number,
    isCustomPlan: boolean,
    returnUrl: string = undefined
  ) {
    const url = `${environment.paymentBaseUrl}/subscription`;
    return this.httpClient.post<ICreditService>(url, {
      planId,
      returnUrl,
      isCustomPlan,
    });
  }

  cancelSubscription(planId: number) {
    const url = `${environment.paymentBaseUrl}/subscription/${planId}/cancel`;
    return this.httpClient.post<ICreditService>(url, {}).toPromise();
  }

  getPricingPlans(): Observable<{
    subscriptionPlans: Array<IPlan>;
    customSubscriptionPlans: ICPlan;
  }> {
    const url = `${environment.paymentBaseUrl}/subscription/plans`;
    return this.httpClient
      .get<{
        subscriptionPlans: {
          subscriptionPlans: Array<IPlan>;
          customSubscriptionPlans: ICPlan;
        };
      }>(url)
      .pipe(
        map((res) => ({
          subscriptionPlans: res.subscriptionPlans.subscriptionPlans,
          customSubscriptionPlans: this.getCustomPlan(
            res.subscriptionPlans.subscriptionPlans,
            res.subscriptionPlans.customSubscriptionPlans
          ),
        })),
        catchError((e) => {
          console.error(e);
          return of({
            subscriptionPlans: [],
            customSubscriptionPlans: null,
          });
        })
      );
  }

  getCustomPlan(
    subscriptionPlans: Array<IPlan>,
    customSubscriptionPlans: ICPlan
  ) {
    if (!customSubscriptionPlans) {
      return null;
    }
    try {
      const customPlanId = customSubscriptionPlans.base_plan_id;
      let customPlan = subscriptionPlans.find(
        (plan) => plan.id === customPlanId
      );
      let {
        id,
        fwd_store_id,
        plan_name,
        price,
        free_credits,
        base_plan_id,
        per_email_rate,
        enable,
      } = customSubscriptionPlans;

      const planNameParts = plan_name.split("Custom - ");
      if (planNameParts.length > 1) {
        plan_name = planNameParts[1];
      }

      return {
        id,
        plan_description: customPlan?.plan_description || "",
        fwd_store_id,
        plan_name,
        price,
        customer_success: customPlan?.customer_success || "",
        journey_quota: customPlan?.journey_quota || "",
        free_credits,
        channels: customPlan?.channels || [],
        rate_card: customPlan?.rate_card || "",
        base_plan_id,
        per_email_rate,
        enable,
      };
    } catch (e) {
      return null;
    }
  }

  /**
   * Retrieves the current store subscription.
   *
   * @return {Promise<IPlan>} A promise that resolves with the current store subscription.
   */
  getCurrentStoreSubscription(): Promise<IPlan> {
    const url = `${environment.paymentBaseUrl}/subscription`;
    return this.httpClient
      .get<{ subscription: Array<IPlan> }>(url)
      .pipe(
        map((res: { subscription: Array<IPlan> }) =>
          res.subscription.length > 0
            ? res.subscription[0]
            : DEFAULT_SUBSCRIPTION_PLAN
        ),
        map((res) => ({
          ...res,
          plan_name: res.plan_name.replace("Custom - ", ""),
        }))
      )
      .toPromise();
  }

  purchaseSubscription(url: string, paymentType: string) {
    this.crispService.billingActivated(CRISP_STATUS.YES);
    if (paymentType === "SHOPIFY_PAYMENTS") {
      window.location.href = url;
    }
  }
}
