import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IToast } from "../interfaces/toast.interface";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  toastList: Array<IToast> = [];
  messageConfiguration: Subject<IToast[]> = new Subject<IToast[]>();

  showToast(config?: IToast) {
    this.toastList.push(config);
    this.clearToastOnInterval(this.toastList.length - 1);
    this.updateObservable();
  }

  updateObservable() {
    this.messageConfiguration.next([...this.toastList]);
  }

  clearToastOnInterval(index: number) {
    setTimeout(() => {
      this.toastList.splice(index, 1);
      this.updateObservable();
    }, 3 * 1000);
  }

  getToast$() {
    return this.messageConfiguration.asObservable();
  }
}
