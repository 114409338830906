import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, UntypedFormControl} from "@angular/forms";
import {IStaticData} from "../../../../core/interfaces/store.interface";
import {ModalService} from "../../../../core/services/modal.service";

@Component({
  selector: "tf-choose-category",
  templateUrl: "./choose-category.component.html",
  styleUrls: ["./choose-category.component.scss"],
})
export class ChooseCategoryComponent {
  @Input() data: IStaticData[];

  selectedCategory: number;
  categoryControl: FormControl = new FormControl<string>("");

  constructor(private modalService: ModalService) {
  }

  close(value?: string) {
    value = value ? this.categoryControl.value : null;
    this.modalService.close(value)
  }
}
