import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PaymentService } from "../../../core/services/payment.service";
import { Observable } from "rxjs";
import { IPlan, ICPlan } from "../../../core/interfaces/subscriptionPlans.interface";
import { ModalService } from "../../../core/services/modal.service";

@Component({
  selector: "tf-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionComponent {
  pricingPlans$: Observable<{
    subscriptionPlans: Array<IPlan>;
    customSubscriptionPlans: ICPlan;
  }> = this.paymentService.getPricingPlans();

  constructor(
    private paymentService: PaymentService,
    private modalRef: ModalService
  ) {}

  close() {
    this.modalRef.close(false);
  }

  async activateSubscription(id: number) {
    try {
      const response = await this.paymentService
        .platformSubscription(id, false, window.location.href)
        .toPromise();
      this.paymentService.purchaseSubscription(
        response.recurring_application_charge.confirmation_url,
        response["paymentType"]
      );
      this.modalRef.close(true);
    } catch (e) {}
  }
}
