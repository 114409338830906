import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { ToastService } from "../../../core/services/toast.service";
import { IToast } from "../../../core/interfaces/toast.interface";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "tf-toast",
  templateUrl: "./tf-toast.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    //write fade in and out animations
    trigger("fade", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      state(
        "*",
        style({
          opacity: 1,
        })
      ),
      transition("void <=> *", animate(500)),
      transition("* <=> void", animate(500)),
    ]),
  ],
})
export class TfToastComponent {
  toastList: Array<IToast> = [];

  constructor(
    protected toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.toastService.getToast$().subscribe((res) => {
      this.toastList = res;
      console.log("change: ", res);
      this.cdr.markForCheck();
    });
  }
}
