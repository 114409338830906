import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CommonUtilService } from "./common.service";
import {
  ICampaign,
  ICampaignDeliveryMetrics,
  ICampaignInfo,
  ICampaignMetricsDestinations,
  ICampaignOrders,
} from "../interfaces/campaign.interface";
import { catchError, map } from "rxjs/operators";
import { IServiceKpi } from "../interfaces/kpi.interface";
import { ISpamScore } from "../interfaces/spamScore.interface";
import { CaseConversion } from "../utils/case-conversion.util";
import { Observable, of, throwError } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class CampaignService {
  constructor(
    private httpClient: HttpClient,
    private commonUtilService: CommonUtilService,
    private caseConversionUtil: CaseConversion,
    private sanitizer: DomSanitizer
  ) {}

  getCampaignsOrderList(
    campaignId: string
  ): Promise<ICampaignOrders[] | { error: string }> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/campaign/${campaignId}/orders`;
    return this.httpClient
      .get<ICampaignOrders[] | { error: string }>(url)
      .toPromise();
  }

  createCampaign(data): Promise<ICampaign> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/campaign`;
    return this.httpClient.post<ICampaign>(url, data).toPromise();
  }

  getCampaignList(
    page: number = 0,
    limit: number = 10
  ): Promise<{
    count: number;
    campaignList: Array<ICampaign>;
  }> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/campaign?limit=${limit}&page=${
      page + 1
    }`;
    return this.httpClient
      .get<{
        count: number;
        campaignList: Array<ICampaign>;
      }>(url)
      .pipe(
        map((res: { count: number; campaignList: Array<ICampaign> }) => ({
          count: res.count,
          campaignList: res.campaignList,
        }))
      )
      .toPromise();
  }

  getCampaign(campaignId: string): Promise<ICampaign> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/campaign/${campaignId}`;
    return this.httpClient.get<ICampaign>(url).toPromise();
  }

  deleteCampaign(campaignId: string): Promise<any> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/campaign/${campaignId}`;
    return this.httpClient.delete(url).toPromise();
  }

  spamScore(data: {
    templateName: string;
    emailSenderDomain: string;
  }): Promise<ISpamScore> {
    const url = `${environment.baseServiceURL}/campaign/spamscore`;
    return this.httpClient.post<ISpamScore>(url, data).toPromise();
  }

  campaignStats(campaignId: string): Promise<{
    kpiResponses: Array<IServiceKpi>;
    campaignRevenue: number;
  }> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/campaign/${campaignId}/stats`;
    return this.httpClient
      .get<{
        kpiResponses: Array<IServiceKpi>;
        campaignRevenue: number;
      }>(url)
      .toPromise();
  }

  campaignDeliveryMetrics(
    campaignId: string
  ): Promise<ICampaignDeliveryMetrics> {
    const url = `${environment.baseServiceURL}/campaign/${campaignId}/metrics`;
    return this.httpClient.get<ICampaignDeliveryMetrics>(url).toPromise();
  }

  getExportedCsvUrl(campaignId: string): Promise<{ url: string }> {
    const url = `${environment.baseServiceURL}/campaign/${campaignId}/export`;
    return this.httpClient.post<{ url: string }>(url, {}).toPromise();
  }

  getDestinationData(
    campaignId: string,
    metrics?: string,
    nextToken?: string
  ): Promise<ICampaignMetricsDestinations> {
    const queryParams = [];
    if (metrics) {
      queryParams.push(`metrics=${metrics}`);
    }
    if (nextToken) {
      queryParams.push(`nextToken=${nextToken}`);
    }
    const url = `${
      environment.baseServiceURL
    }/campaign/${campaignId}/destinations?${queryParams.join("&")}`;
    return this.httpClient.get<ICampaignMetricsDestinations>(url).toPromise();
  }

  getDomainAnalysisData(campaignId: string, metrics: string): Promise<any> {
    const url = `${environment.baseServiceURL}/campaign/${campaignId}/getDomainAnalysisChartData?metrics=${metrics}`;
    return this.httpClient.get<any>(url).toPromise();
  }

  timeSpreadChartData(
    campaignId: string,
    metrices: string[]
  ): Promise<{ [key: string]: { hour: string; count: number } }[]> {
    const url = `${
      environment.baseServiceURL
    }/campaign/${campaignId}/timespread?metrics=${metrices.join(",")}`;
    return this.httpClient
      .get<{ [key: string]: { hour: string; count: number } }[]>(url)
      .toPromise();
  }

  getCampaignInfo(campaignId: string): Observable<ICampaignInfo> {
    const url = `${environment.baseServiceURL}/campaign/${campaignId}`;
    return this.httpClient.get(url).pipe(
      map((res: ICampaignInfo) => {
        res.message.body = this.sanitizer.bypassSecurityTrustHtml(
          res.message.body as string
        );
        return res;
      }),
      catchError(() => {
        return of({
          name: "",
          createdAt: "",
          schedule: "",
          status: "",
          channel: "",
          message: {
            subject: "",
            body: null,
            fromAddress: "",
          },
          segment: {
            name: "",
          },
        });
      })
    );
  }
}
