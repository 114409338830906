import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { BlockedStoreGuard } from "./core/guards/blockedStore.guard";
import { FlagBasedPreloadingStrategy } from "./flagBasedPreloading";

const routes: Routes = [
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [BlockedStoreGuard, AuthGuard],
  },
  {
    path: "unsubscribe/:customerId/:userId",
    redirectTo: "o/unsubscribe/:customerId/:userId",
  },
  {
    path: "blocked",
    redirectTo: "o/blocked",
  },

  {
    path: "o",
    loadChildren: () =>
      import("./external-pages/external-pages.module").then(
        (m) => m.ExternalPagesModule
      ),
  },
  {
    path: "**",
    redirectTo: "o/404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      preloadingStrategy: FlagBasedPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
