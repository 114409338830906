<div class="sidebar-wrapper fixed left-0 top-0 bottom-0 h-full bg-white px-2 py-4
        w-[60px] flex flex-col items-center justify-between overflow-y-auto scrollbar-thin
        scrollbar-thumb-ternary-200 scrollbar-track-ternary-100 overflow-x-hidden"
>
    <div class="mb-8">
        <img ngSrc="/assets/images/logo.png" alt="logo" width="64" height="64"/>
    </div>
    <div>
        <ul class="flex flex-col">
            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    tfTooltip
                    content="Dashboard"
                    elementId="dashboard-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
                    [routerLink]="['/dashboard']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
            >
                <i class="bi-house scale-125"></i>
            </li>
            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    [routerLink]="['/segments']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
                    tfTooltip
                    content="Segments"
                    elementId="segment-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
            >
                <i class="bi-view-stacked scale-125"></i>
            </li>
            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    [routerLink]="['/campaigns']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
                    tfTooltip
                    content="Campaign"
                    elementId="campaign-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
            >
                <i class="bi-box scale-125"></i>
            </li>
            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    [routerLink]="['/journey']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
                    tfTooltip
                    content="Journey"
                    elementId="journey-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
            >
                <i class="bi-diagram-3 scale-125"></i>
            </li>

            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    [routerLink]="['/templates']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
                    tfTooltip
                    content="Templates"
                    elementId="template-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
            >
                <i class="bi-file-break scale-125"></i>
            </li>
            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    [routerLink]="['/library']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
                    tfTooltip
                    content="Library"
                    elementId="library-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
            >
                <i class="bi-folder scale-125"></i>
            </li>

            <li
                    class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                    [routerLink]="['/contacts']"
                    routerLinkActive="text-secondary-600 bg-secondary-50"
                    tfTooltip
                    content="Contacts"
                    elementId="contact-tooltip"
                    [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
            >
                <i class="bi-people scale-125"></i>
            </li>
        </ul>
    </div>

    <div class="">
    <span
            class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
            [routerLink]="['/utility/settings']"
            routerLinkActive="text-secondary-600 bg-secondary-50"
            tfTooltip
            content="Settings"
            elementId="settings-tooltip"
            [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
    >
      <i class="bi-gear scale-125"></i>
    </span>

        <span
                class="my-2 p-3 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
                (click)="switchAccount()"
                tfTooltip
                content="Switch Store"
                elementId="switch-account-tooltip"
                [tooltipPosition]="TOOLTIP_POSITION.RIGHT"
        >
      <i class="bi-arrow-left-right scale-125"></i>
    </span>

        <span
                class="inline-flex items-center justify-center h-[2.875rem] w-[2.875rem] rounded-full bg-secondary-500 font-semibold text-white leading-none cursor-pointer"
                (click)="openPreview($event)"
        >
      <span class="uppercase">
        {{ (currentUser$ | async)?.name | slice : 0 : 2 }}
      </span>
    </span>
    </div>
</div>
<!--<tf-toasts aria-atomic="true" aria-description="toaster component"></tf-toasts>-->
<div class="rounded-lg flex flex-col hidden shadow-2xl fixed bg-white
        max-w-screen-sm w-[300px] bottom-[10px] left-[62px] z-50"
     #profile>
    <ng-container *ngIf="currentUser$ | async as user">
        <div class="flex items-start justify-start gap-4 border-b-[1px] p-4">
            <div class="w-12 h-12 rounded-full bg-secondary-600 uppercase flex items-center justify-center text-white">
                {{ user?.name | slice : 0 : 2 }}
            </div>
            <div>
                <h4 class="text-lg font-semibold">{{ user?.name }}</h4>
                <p class="text-sm text-gray-500">{{ user?.email }}</p>
            </div>
        </div>
        <div class="mb-2 py-2 px-4 text-sm ">
            <table>
                <tr>
                    <td>
                        <span class="flex items-center justify-start gap-2">
                            <i class="bi-person" aria-expanded="false"></i> Role
                        </span>
                    </td>
                    <td>:</td>
                    <td class="px-2 font-semibold">{{ user['custom:role'] == Roles.ADMIN ? "Admin" : "Staff" }}</td>
                </tr>
                <tr>
                    <td>
                        <span class="flex items-center justify-start gap-2">
                            <i class="bi-shop" aria-expanded="false"></i> Shop
                        </span>
                    </td>
                    <td>:</td>
                    <td class="px-2"><a href="https://{{ selectedStore?.store_url }}" target="_blank"
                                        *ngIf="selectedStore$ | async as selectedStore"
                                        class="flex items-center gap-2 underline">
                        <p class="text-sm font-semibold">{{ selectedStore?.store_name }}</p>
                    </a></td>
                </tr>
            </table>
        </div>
    </ng-container>
    <div class="p-2 flex items-center justify-end">
        <tf-button name="Sign Out" [buttonStyle]="BUTTON_STYLES_TYPE.PRIMARY_GHOST_SMALL" (onClick)="logout()"/>
    </div>
</div>
