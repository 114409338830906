<div class="modal-wrapper">
  <section
    class="hs-overlay w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none open"
    #modal>
    <div
      class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
      <ng-content></ng-content>
    </div>
  </section>
  
  <div class="modal-overlay" #overlay (click)="onClose()"></div>
</div>
