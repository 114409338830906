import { Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { SetupComponent } from "../account/auth/onboarding/setup/setup.component";
import { TriggerSetupModal } from "../core/store/status/status.action";
import { SectionAvailabilityStatus } from "../core/store/status/status.state";
import { NGXLogger } from "ngx-logger";
import { ModalService } from "../core/services/modal.service";

@Component({
  selector: "tf-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent {
  @Select(SectionAvailabilityStatus.getSetupModalTrigger)
  setupModalTrigger$: Observable<boolean>;

  constructor(
    private modalService: ModalService,
    private store: Store,
    private logger: NGXLogger
  ) {
    // commenting as per the requirement change
    // this._listenSetUpModalTrigger();
  }

  _listenSetUpModalTrigger(): void {
    let modalRef: any | null = null;
    this.setupModalTrigger$.subscribe(async (res: boolean) => {
      if (res && modalRef === null) {
        try {
          modalRef = this.modalService.open(SetupComponent);
          await this.modalService.result();
          modalRef = null;
          this.store.dispatch(new TriggerSetupModal(false));
        } catch (error) {
          this.logger.error(
            "Something went wrong on listening modal status",
            error
          );
        }
      }
    });
  }
}
