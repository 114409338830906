import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { ShopifyStoreState } from "../../../../core/store/user-store/user-store.state";
import {
  ISetupStore,
  IShopifyStore,
  IStaticData,
} from "../../../../core/interfaces/store.interface";
import { CommonUtilService } from "../../../../core/services/common.service";
import { CoreBackendService } from "../../../../core/services/core.services";
import { StaticService } from "../../../../core/services/static.service";
import { StoreService } from "../../../../core/services/store.service";
import { ModalService } from "../../../../core/services/modal.service";
import { ToastService } from "../../../../core/services/toast.service";

@Component({
  selector: "tf-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["./setup.component.scss"],
})
export class SetupComponent implements OnInit {
  staticData: IStaticData;
  activeIndex = "channels";
  setupStore: ISetupStore;

  @Select(ShopifyStoreState.getSelectedShopifyStore)
  selectedStore$: Observable<IShopifyStore>;

  constructor(
    private staticService: StaticService,
    private commonService: CommonUtilService,
    private storeService: StoreService,
    private router: Router,
    private toast: ToastService,
    private coreService: CoreBackendService,
    private activeModal: ModalService
  ) {}

  ngOnInit(): void {
    this.getStaticData().then();
    this.setupStore = {
      storeId: this.commonService.getActiveCustomerId(),
    };
  }

  async getStaticData(): Promise<void> {
    try {
      this.staticData = await this.staticService.getStaticData();
    } catch (error) {
      this.activeModal.close();
    }
  }

  selectChannels(channels: number[]) {
    this.setupStore["channels"] = channels;
    const email = this.staticData.channels.find(
      (channel) => channel.channel_key === "email"
    );
    if (email && channels.includes(email.id)) {
      this.skip("identity");
    } else {
      this.skip("category");
    }
  }

  selectCategory(category: number) {
    this.setupStore["category"] = category;
    this.skip("goal");
  }

  selectGoals(goal: number) {
    this.setupStore["goal"] = goal;
    this.skip("reference");
  }

  selectReference(reference: number) {
    this.setupStore["reference"] = reference;
    this.skip();
  }

  skip(next?: string) {
    if (!next) {
      this.submit().then();
    } else {
      this.activeIndex = next;
    }
  }

  async submit(): Promise<void> {
    try {
      await this.storeService.setupStore(this.setupStore);
      const stores = await this.coreService.getEnrolledStores();
      this.activeModal.close();
      if (stores.rows.length > 0) {
        this.coreService.updateStore(stores.rows);
      }
    } catch (err) {
      this.toast.showToast({ message: err });
      this.router
        .navigate(["/dashboard"], { queryParams: { reload: true } })
        .then();
    } finally {
      this._getSelectedStore();
    }
  }

  _getSelectedStore(): void {
    this.selectedStore$.subscribe((res: IShopifyStore) => {
      if (res) {
        this.router
          .navigate(["/dashboard"], { queryParams: { reload: true } })
          .then();
      }
    });
  }
}
