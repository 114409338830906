import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { catchError, switchMap, tap } from "rxjs/operators";
import { IUnitPriceService } from "../../../core/interfaces/credit.interface";
import { CustomValidators } from "../../../core/classes/custom-validators";
import { PaymentService } from "../../../core/services/payment.service";
import { AuthenticationService } from "../../../core/services/auth.service";
import { ToastMessages } from "../../../core/constants/toast-message.constant";
import { NGXLogger } from "ngx-logger";
import { of } from "rxjs";
import { ModalService } from "../../../core/services/modal.service";
import { ToastService } from "../../../core/services/toast.service";
import { LoaderTypes } from "../../../core/enums/loader-type.enum";

@Component({
  selector: "tf-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  channel: FormControl<string> = new FormControl<string>("EMAIL");
  userData = { username: "", attributes: { email: "" } };

  isLoading: boolean = false;
  isCountLoading: boolean = false;
  price: FormControl = new FormControl<number>(
    null,
    Validators.compose([
      Validators.required,
      CustomValidators.positiveValue("negativeNumber"),
      CustomValidators.validNumber(),
      CustomValidators.validPrice(),
    ]),
  );
  unitPrice: IUnitPriceService = { emails: 0, sms: 0 };

  constructor(
    private paymentService: PaymentService,
    private authService: AuthenticationService,
    private toast: ToastService,
    private logger: NGXLogger,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this._checkUnitPrice();
    this.authService
      .currentUser()
      .then((res) => {
        this.logger.log(res);
        this.userData = res;
      })
      .catch((_) => {});
  }

  close(): void {
    this.modalService.close();
  }

  checkout() {
    this.price.markAllAsTouched();
    this.logger.debug(this.price.errors);

    if (this.price.valid) {
      this.isLoading = true;
      this.paymentService
        .addCredits(this.price.value, this.channel.value)
        .subscribe((res) => {
          if (res["paymentType"] == "STRIPE") {
            window.location.href = res.url;
          } else if (res["paymentType"] == "SHOPIFY_PAYMENTS") {
            if (res.data.appPurchaseOneTimeCreate?.confirmationUrl) {
              window.location.href =
                res.data.appPurchaseOneTimeCreate.confirmationUrl;
              this.logger.log(res);
            } else {
              this.toast.showToast({
                message: ToastMessages.SOMETHING_WENT_WRONG,
                icon: "bx bx-x",
              });
            }
          }
          this.isLoading = false;
          this.logger.log(res);
          if (res.error) {
            this.logger.error(res.error.message);
            this.isLoading = false;
          }
        });
    }
  }

  _checkUnitPrice(): void {
    this.price.valueChanges
      .pipe(
        tap(() => {
          this.isCountLoading = true;
        }),
        switchMap((amount: number) => {
          if (this.channel.value === "WHATSAPP") return of(null);
          return this.paymentService.getUnitRate(amount);
        }),
        tap(() => {
          this.isCountLoading = false;
        }),
        catchError((err, caught) => caught),
      )

      .subscribe({
        next: (res: IUnitPriceService) => {
          this.unitPrice = res;
        },
        error: (err) => {
          this.logger.error("failed to get unit count", err);
        },
      });
  }

  protected readonly LoaderTypes = LoaderTypes;
}
