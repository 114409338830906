import { IShopifyStore } from "../../interfaces/store.interface";
import { IWallet } from "../../interfaces/credit.interface";

export class SelectedStore {
  static readonly type = "[Shopify Store] Set Selected Store";

  constructor(public payload: IShopifyStore) {}
}

export class CurrentSubscription {
  static readonly type = "[Shopify Store] Current Subscription";
}

export class UpdateWallet {
  static readonly type = "[Shopify Store] Add wallet";

  constructor() {}
}

export class OnSwitchStore {
  static readonly type = "[Shopify Store] On Switch Store";

  constructor() {}
}
