import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IChannel } from "../../interfaces/channel.interface";
import {
  OnSwitchStoreIdentity,
  updateChannelStatus,
  UpdateVerifiedEmailIdentity,
} from "./identities.action";
import { NGXLogger } from "ngx-logger";

export class IdentityStateModel {
  channels: Array<IChannel>;
  isSMSEnabled: boolean;
  isEmailEnabled: boolean;
  isInAppMessageEnabled: boolean;
  isEmailVerified: boolean;
  isWhatsappEnabled: boolean;
}

@State<IdentityStateModel>({
  name: "Identity",
  defaults: {
    channels: [],
    isSMSEnabled: false,
    isEmailEnabled: false,
    isInAppMessageEnabled: false,
    isEmailVerified: false,
    isWhatsappEnabled: false,
  },
})
@Injectable({
  providedIn: "root",
})
export class IdentityState {
  constructor(private logger: NGXLogger) {}

  @Selector()
  static getChannelStatus(state: IdentityStateModel) {
    const {
      isEmailEnabled,
      isSMSEnabled,
      isInAppMessageEnabled,
      isEmailVerified,
      isWhatsappEnabled,
    } = state;
    return {
      isEmailEnabled,
      isSMSEnabled,
      isInAppMessageEnabled,
      isEmailVerified,
      isWhatsappEnabled,
    };
  }

  @Action(updateChannelStatus)
  updateChannelStatus(
    { getState, patchState }: StateContext<IdentityStateModel>,
    { payload }: updateChannelStatus
  ) {
    const state = getState();
    const {
      isSMSEnabled,
      isEmailEnabled,
      isInAppMessageEnabled,
      isWhatsappEnabled,
    } = this._updateChannelStatus(payload);

    patchState({
      ...state,
      isSMSEnabled,
      isEmailEnabled,
      isInAppMessageEnabled,
      isWhatsappEnabled,
      channels: payload,
    });
  }

  _updateChannelStatus(channelData: Array<IChannel>) {
    const status = {
      isSMSEnabled: false,
      isEmailEnabled: false,
      isInAppMessageEnabled: false,
      isWhatsappEnabled: false,
    };
    const channel = {
      Email: (res: IChannel) => (status.isEmailEnabled = res?.Enabled || false),
      SMS: (res: IChannel) => (status.isSMSEnabled = res?.Enabled || false),
      "In App Messages": (res: IChannel) =>
        (status.isInAppMessageEnabled = res?.Enabled || false),
      WhatsApp: (res: IChannel) =>
        (status.isWhatsappEnabled = res?.Enabled || false),
    };
    try {
      channelData.forEach((res: IChannel) => {
        if (!channel[res.ChannelName]) return;
        return channel[res.ChannelName](res);
      });
    } catch (e) {
      this.logger.error("Channel Status Update Failed: ", e);
    }
    return status;
  }

  @Action(UpdateVerifiedEmailIdentity)
  updateVerifiedEmailIdentityStatus(
    { getState, patchState }: StateContext<IdentityStateModel>,
    { payload }: UpdateVerifiedEmailIdentity
  ) {
    const state = getState();
    patchState({
      ...state,
      isEmailVerified: payload,
    });
  }

  @Action(OnSwitchStoreIdentity)
  onSwitchStoreIdentity({ patchState }: StateContext<IdentityStateModel>) {
    patchState({
      channels: [],
      isSMSEnabled: false,
      isEmailEnabled: false,
      isInAppMessageEnabled: false,
      isEmailVerified: false,
      isWhatsappEnabled: false,
    });
  }
}
