import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import {
  IPlan,
  IPlanChannel,
} from "../../core/interfaces/subscriptionPlans.interface";
import { BUTTON_STYLES_TYPE } from "../../core/constants/button.constant";

@Component({
  selector: "tf-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanComponent {
  @Input() plan: IPlan;
  @Input() buttonName: string;
  @Input() showPurchaseButton: boolean;
  @Input() isCurrentSubscription: boolean = false;
  @Input() previousLevelPlanName: string;
  @Output() activateSubscription: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() cancelSubscription?: EventEmitter<{planId: number, planName: string}> =
    new EventEmitter<{planId: number, planName: string}>();

  subscribePlatform() {
    this.activateSubscription.emit(this.plan.id);
  }

  cancelPlatformSubscription() {
    this.cancelSubscription.emit({planId: this.plan.id, planName: this.plan.plan_name});
  }

  get channels() {
    return this.plan.channels
      .filter((res: IPlanChannel) => res.enabled)
      .map((res: IPlanChannel) => res.channel_name)
      .join(", ");
  }

  protected readonly BUTTON_STYLES_TYPE = BUTTON_STYLES_TYPE;
}
