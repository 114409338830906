import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {IStaticData} from "../../../../core/interfaces/store.interface";
import {ModalService} from "../../../../core/services/modal.service";

@Component({
  selector: "tf-choose-reference",
  templateUrl: "./choose-reference.component.html",
  styleUrls: ["./choose-reference.component.scss"],
})
export class ChooseReferenceComponent {
  @Input() data: IStaticData[];

  referenceControl: FormControl = new FormControl<string>("");

  constructor(private modalService: ModalService) {
  }

  close(value?: string) {
    value ? this.modalService.close(this.referenceControl.value) : this.modalService.close();
  }
}
