import {Component} from "@angular/core";

@Component({
  selector: "tf-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})

/**
 * Footer component
 */
export class FooterComponent {
  year: number = new Date().getFullYear();

}
