export class SegmentAvailabilityStatus {
  static readonly type =
    "[Segment Availability] Set segment availability status";
  constructor(public payload: any) {}
}

export class CampaignAvailabilityStatus {
  static readonly type =
    "[Campaign Availability] Set campaign availability status";
  constructor(public payload: any) {}
}

export class JourneyAvailabilityStatus {
  static readonly type =
    "[Journey Availability] Set Journey availability status";
  constructor(public payload: any) {}
}

export class TriggerSetupModal {
  static readonly type = "[Trigger Setup Modal]";
  constructor(public payload: boolean) {}
}
