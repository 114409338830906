import { Component, EventEmitter, Output } from "@angular/core";
import { StoreService } from "../../../core/services/store.service";
import {
  IEnrolledStore,
  IShopifyStore,
} from "../../../core/interfaces/store.interface";
import { Select, Store } from "@ngxs/store";
import { ShopifyStoreState } from "../../../core/store/user-store/user-store.state";
import { Observable } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { FormControl } from "@angular/forms";
import { IUser } from "../../../core/interfaces/user.interface";
import { UserStoreState } from "../../../core/store/user/user.state";
import { ModalService } from "../../../core/services/modal.service";
import { LoaderTypes } from "../../../core/enums/loader-type.enum";
import { OnSwitchStore } from "../../../core/store/user-store/user-store.action";
import { OnSwitchStoreIdentity } from "../../../core/store/identities/identities.action";
import { onSwitchStoreSegmentList } from "../../../core/store/segments/segment.action";

@Component({
  selector: "tf-switch-account",
  templateUrl: "./switch-account.component.html",
  styleUrls: ["./switch-account.component.scss"],
})
export class SwitchAccountComponent {
  protected readonly LoaderTypes = LoaderTypes;
  @Select(ShopifyStoreState.getSelectedShopifyStore)
  currentStore$: Observable<IShopifyStore>;

  @Select(UserStoreState.getCurrentUser) currentUser$: Observable<IUser>;

  search: FormControl<string> = new FormControl<string>("");
  currentStore: IShopifyStore = null;

  shopifyStores$: Observable<Array<IEnrolledStore>> =
    this.storeService.getEnrolledStoresList("");

  @Output() reFetchChannels: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  isLoading: boolean = false;

  constructor(
    private storeService: StoreService,
    private logger: NGXLogger,
    private modalService: ModalService,
    private store: Store,
  ) {
    this.onSearch();
    this._getCurrentStore();
  }

  close() {
    this.modalService.close();
  }

  /**
   * Switches the account to a different Shopify store.
   *
   * @param {IShopifyStore} store - The store to switch to.
   * @return {Promise<void>} - A promise that resolves when the account has been switched successfully.
   */
  async switchAccount(store: IEnrolledStore): Promise<void> {
    try {
      this.isLoading = true;
      await this.storeService.switchStore(store.fwdStoreId);
      Promise.all([
        this.store.dispatch(new OnSwitchStore()).toPromise(),
        this.store.dispatch(new OnSwitchStoreIdentity()).toPromise(),
        this.store.dispatch(new onSwitchStoreSegmentList()).toPromise(),
      ]).then((res) => {
        this.logger.debug("on successful clean up", res);
        this.isLoading = false;
        this.modalService.close({ success: true, currentStore: store });
        window.location.href = "/dashboard";
      });
    } catch (e) {
      this.isLoading = false;
      this.modalService.close({ success: false, currentStore: undefined });
      this.logger.error("Fetching store existence failed: ", e);
    }
  }

  onSearch() {
    this.search.valueChanges.subscribe((res) => {
      this.shopifyStores$ = this.storeService.getEnrolledStoresList(res);
    });
  }

  _getCurrentStore() {
    this.currentStore$.subscribe((res: IShopifyStore) => {
      this.currentStore = res;
    });
  }
}
