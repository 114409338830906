<div
  class="flex bg-gray-200/[.5] hover:bg-gray-200 rounded-xl transition p-1">
  <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
    <button type="button"
            *ngFor="let tab of tabItems"
            class="hs-tab-active:bg-white hs-tab-active:text-gray-700 hs-tab-active:shadow-sm text-xs text-gray-500 hover:text-gray-700 font-medium rounded-lg py-2 px-3"
            [ngClass]="{active: tab.active}"
            [id]="'tab-{{tabItem.id}}'" [attr.data-hs-tab]="'#'+tabGroup"
            [attr.aria-controls]="tabGroup" role="tab"
            (click)="tabSwitch(tab)">
      {{ tab.label }}
    </button>
  </nav>
</div>
