export const BUTTON_STYLES = {
  PRIMARY:
    "py-3 px-4 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-950 text-white disabled:opacity-50 disabled:pointer-events-none",
  SECONDARY:
    "py-3 px-4 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-500 text-white disabled:opacity-50 disabled:pointer-events-none",
  DANGER:
    "py-3 px-4 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-500 text-white disabled:opacity-50 disabled:pointer-events-none",
  PRIMARY_SMALL:
    "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-950 text-white disabled:opacity-50 disabled:pointer-events-none",
  SECONDARY_SMALL:
    "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-secondary-500 text-white disabled:opacity-50 disabled:pointer-events-none",
  DANGER_SMALL:
    "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-500 text-white disabled:opacity-50 disabled:pointer-events-none",
  PRIMARY_GHOST:
    "py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-primary-950 hover:bg-primary-100 hover:text-primary-800 disabled:opacity-50 disabled:pointer-events-none",
  PRIMARY_GHOST_SMALL:
    "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-primary-950 hover:bg-primary-100 hover:text-primary-800 disabled:opacity-50 disabled:pointer-events-none",
  PRIMARY_LINK:
    "py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none",
  SECONDARY_GHOST:
    "py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-secondary-500 hover:bg-secondary-100 hover:text-secondary-600 disabled:opacity-50 disabled:pointer-events-none",
  DANGER_GHOST:
    "py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-secondary-500 hover:bg-red-100 hover:text-red-600 disabled:opacity-50 disabled:pointer-events-none",
  SECONDARY_GHOST_SMALL:
    "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-secondary-500 hover:bg-secondary-100 hover:text-secondary-600 disabled:opacity-50 disabled:pointer-events-none",
  DANGER_GHOST_SMALL:
    "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-500 hover:bg-red-100 hover:text-red-600 disabled:opacity-50 disabled:pointer-events-none",
};

export const BUTTON_STYLES_TYPE = {
  PRIMARY: "PRIMARY",
  PRIMARY_SMALL: "PRIMARY_SMALL",
  PRIMARY_GHOST: "PRIMARY_GHOST",
  PRIMARY_LINK: "PRIMARY_LINK",
  SECONDARY_GHOST: "SECONDARY_GHOST",
  SECONDARY_SMALL: "SECONDARY_SMALL",
  SECONDARY: "SECONDARY",
  SECONDARY_GHOST_SMALL: "SECONDARY_GHOST_SMALL",
  PRIMARY_GHOST_SMALL: "PRIMARY_GHOST_SMALL",
  DANGER_GHOST: "DANGER_GHOST",
  DANGER: "DANGER",
  DANGER_SMALL: "DANGER_SMALL",
  DANGER_GHOST_SMALL: "DANGER_GHOST_SMALL",
};

export const RadioSize = {
  md: "px-2 py-3",
  sm: "py-2 px-3 text-sm",
};

export const BADGE_STYLES = {
  DEFAULT: "bg-blue-100 text-blue-800",
  SUCCESS: "bg-green-100 text-green-800",
  WARNING: "bg-orange-100 text-orange-800",
  INFO: "bg-purple-100 text-purple-800",
  DANGER: "bg-red-100 text-red-800",
  DISABLED: "bg-secondary-100 text-secondary-800",
};
