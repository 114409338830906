import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "tf-checkbox",
  templateUrl: "./tf-checkbox.component.html",
  styleUrls: ["./tf-checkbox.component.scss"],
})
export class TfCheckboxComponent implements OnInit {
  @Input() id!: string;
  @Input() name!: string;
  @Input() group?: string;
  @Input() description?: string;
  @Input() checked?: boolean = false;

  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onChanged(checked: boolean) {
    this.onChange.emit(checked);
  }
}
