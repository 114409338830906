import { Component, Input, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import {
  IEndpointOrder,
  ISegmentAudience,
} from "../../core/interfaces/segment.interface";
import { SegmentService } from "../../core/services/segment.service";
import { ShopifyStoreState } from "../../core/store/user-store/user-store.state";
import { ModalService } from "../../core/services/modal.service";

@Component({
  selector: "tf-user-orders",
  templateUrl: "./user-orders.component.html",
  styleUrls: ["./user-orders.component.scss"],
})
export class UserOrdersComponent implements OnInit {
  @Input() segmentId: string;
  @Input() endpoint: ISegmentAudience;
  constructor(
    private segmentService: SegmentService,
    private activeModal: ModalService
  ) {}
  @Select(ShopifyStoreState.getCurrency) currency$: Observable<string>;
  orders: IEndpointOrder[] = [];
  ngOnInit(): void {
    this.getOrders().then();
  }

  async getOrders() {
    const responses = await this.segmentService.getEndpointOrders(
      this.segmentId,
      this.endpoint.id
    );
    this.orders = responses.map((response) => ({
      ...response,
      shopUrl: `https://${response.shopUrl}.myshopify.com/admin/orders/${response.id}`,
    }));
  }

  close() {
    this.activeModal.close();
  }
}
