import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

declare let mixpanel: any;
declare let $crisp: any;

@Injectable({
  providedIn: "root",
})
export class CrispService {
  constructor() {}

  private pushMixPanelEvent(event: string, data: Object) {
    if (environment.production) {
      try {
        mixpanel.track(event, data);
      } catch (e) {
        console.error("pushing mixpanel event failed", e);
      }
    }
  }

  private pushCrispEvent(...data) {
    try {
      $crisp.push(["set", "session:data", [[...data]]]);
    } catch (e) {
      console.error("pushing crisp event failed", e);
    }
  }

  signUp(email: string) {
    this.pushMixPanelEvent("sign_up", { email });
    this.pushCrispEvent(["sign_up", email]);
  }

  login(email: string) {
    this.pushMixPanelEvent("login", {
      email,
    });
    this.pushCrispEvent(["login", email]);
  }

  domainVerification(status: string, verifiedDomain: string) {
    this.pushMixPanelEvent("domain_verification_status", {
      status,
      verifiedDomain,
    });
    this.pushCrispEvent(
      ["domain_verification_status", status],
      ["verified_domain", verifiedDomain]
    );
  }

  // emailIdentities() {
  //   this.pushCrispEvent(["set", "session:event", [[]]]);
  // }

  billingActivated(status: string) {
    this.pushMixPanelEvent("billing_activated", { status });
    this.pushCrispEvent(["billing_activated", status]);
  }

  campaignSent(status: string, campaignName: string) {
    this.pushMixPanelEvent("campaign_sent", { status, campaignName });
    this.pushCrispEvent(
      ["campaign_sent", status],
      ["campaign_name", campaignName]
    );
  }

  journeySetup(status: string) {
    this.pushMixPanelEvent("journey_setup", { status });
    this.pushCrispEvent(["journey_setup", status]);
  }

  segmentsCreated(status: string) {
    this.pushMixPanelEvent("segments_created", { status });
    this.pushCrispEvent(["segments_created", status]);
  }

  trackerEnabled(status: string) {
    this.pushMixPanelEvent("tracker_enabled", { status });
    this.pushCrispEvent(["tracker_enabled", status]);
  }

  lowCredit(status: string, creditAmount: number) {
    this.pushMixPanelEvent("low_credit", { status, creditAmount });
    this.pushCrispEvent(
      ["low_credit", status],
      ["credit_amount", creditAmount]
    );
  }

  templateCreated(status: string, templateName: string) {
    this.pushMixPanelEvent("template_created", { status, templateName });
    this.pushCrispEvent(
      ["template_created", status],
      ["template_name", templateName]
    );
  }

  subscriptionStatus(status: string) {
    this.pushMixPanelEvent("subscription_status", { status });
    this.pushCrispEvent(["subscription_status", status]);
  }
}
