import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FormFieldErrorMessages } from "../../core/constants/error-messages.constant";

@Component({
  selector: "tf-field-error-message",
  templateUrl: "./field-error-message.component.html",
  styleUrls: ["./field-error-message.component.scss"],
})
export class FieldErrorMessageComponent {
  @Input() errorControl: AbstractControl;
  @Input() force: boolean = false;
  @Input() message: string;
  @Input() directMessageMode: boolean = false;

  errorCodes = {
    required: FormFieldErrorMessages.REQUIRED,
    email: FormFieldErrorMessages.INVALID_EMAIL,
    lengthExceed: FormFieldErrorMessages.CAMPAIGN_DESCRIPTION_LENGTH_LIMIT,
    invalidPercentage: FormFieldErrorMessages.CAMPAIGN_HOLD_OUT_PERCENTAGE,
    pastDate: FormFieldErrorMessages.PAST_DATE,
    invalidDayOrHour: FormFieldErrorMessages.INVALID_DAY_OR_HOUR,
    startDateGreaterThanEndDate:
      FormFieldErrorMessages.START_DATE_GREATER_THAN_END_DATE,
    invalidMetricValue: FormFieldErrorMessages.INVALID_METRIC_VALUE,
    maxAmountExceed: FormFieldErrorMessages.MAX_AMOUNT_EXCEED,
    negativeNumber: FormFieldErrorMessages.NEGATIVE_NUMBER,
    invalidDomain: FormFieldErrorMessages.INVALID_DOMAIN,
    invalidTime: FormFieldErrorMessages.INVALID_TIME,
  };
  constructor() {}

  getErrorMessage(): string {
    const isFormTouchedOrDirty =
      this.errorControl.dirty || this.errorControl.touched;
    const isForcedOrTouched = isFormTouchedOrDirty || this.force;
    return isForcedOrTouched && this.errorControl.errors
      ? this.errorCodes[Object.keys(this.errorControl.errors)[0]]
      : "";
  }
}
