import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonUtilService } from "./common.service";
import { environment } from "../../../environments/environment";
import {
  IAttribute,
  IEndpointOrder,
  IPredefinedAllSegmentsDetail,
  IPredefinedSegmentDetail,
  ISegment,
  ISegmentAudienceService,
  ISegmentDetail,
  ISegmentEstimate,
  ISegmentEstimateService,
  ISegmentForm,
  ISegmentGroup,
  ISegmentService,
  ISegmentServiceDetail,
} from "../interfaces/segment.interface";
import { map } from "rxjs/internal/operators/map";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { CaseConversion } from "../utils/case-conversion.util";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class SegmentService {
  constructor(
    private httpClient: HttpClient,
    private commonUtilService: CommonUtilService,
    private caseConversionUtil: CaseConversion,
    private logger: NGXLogger
  ) {}

  getAllSegments(
    page = 0,
    withPagination: boolean = true
  ): Promise<{
    count: number;
    segments: Array<ISegment>;
    topPerformingSegment: string;
  }> {
    const url = withPagination
      ? `${environment.segmentServiceBaseURL}/segments?limit=10&page=${
          page + 1
        }`
      : `${environment.segmentServiceBaseURL}/segments`;

    return this.httpClient
      .get<{
        count: number;
        segments: Array<ISegmentService>;
        topPerformingSegment: string;
      }>(url)
      .pipe(
        map(
          (res: {
            count: number;
            segments: Array<ISegmentService>;
            topPerformingSegment: string;
          }) => this.caseConversionUtil.toCamelCase(res)
        )
      )
      .toPromise();
  }

  getSegmentAttributes(): Promise<Array<IAttribute>> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/attributes`;
    return this.httpClient.get<Array<IAttribute>>(url).toPromise();
  }

  createSegment(segment: any): Promise<any> {
    const url = `${environment.segmentServiceBaseURL}/segments`;
    return this.httpClient.post(url, segment).toPromise();
  }

  getSegment(segmentId: string): Promise<ISegmentDetail> {
    const url = `${environment.segmentServiceBaseURL}/segments/${segmentId}`;
    return this.httpClient
      .get<ISegmentServiceDetail>(url)
      .pipe(
        map((res) => this.caseConversionUtil.toCamelCase(res)),
        map((res) => {
          return { ...res, query: JSON.parse(res.query) };
        })
      )
      .toPromise();
  }

  patchSegment(segmentId: string, segment: ISegmentForm): Promise<any> {
    const url = `${environment.segmentServiceBaseURL}/segments/${segmentId}`;
    return this.httpClient.patch(url, segment).toPromise();
  }

  deleteSegment(segmentId: string): Promise<any> {
    const url = `${environment.segmentServiceBaseURL}/segments/${segmentId}`;
    return this.httpClient.delete(url).toPromise();
  }

  async getSegmentEndpoints(
    segmentId: string,
    page: number = 1,
    limit: number = 10
  ): Promise<ISegmentAudienceService> {
    const url = `${environment.segmentServiceBaseURL}/segments/${segmentId}/endpoints?limit=${limit}&page=${page}`;
    return this.httpClient.get<ISegmentAudienceService>(url).toPromise();
  }

  getEndpointOrders(
    segmentId: string,
    endpointId: string
  ): Promise<Array<IEndpointOrder>> {
    const url = `${environment.segmentServiceBaseURL}/segments/${segmentId}/endpoints/${endpointId}/orders`;
    return this.httpClient
      .get<IEndpointOrder[]>(url)
      .pipe(map((res) => this.caseConversionUtil.toCamelCase(res)))
      .toPromise();
  }

  getEstimates(
    segmentGroups: Array<ISegmentGroup>
  ): Observable<Array<ISegmentEstimate>> {
    const url = `${environment.segmentServiceBaseURL}/segments/estimate`;
    return this.httpClient
      .post<Array<ISegmentEstimateService>>(url, segmentGroups)
      .pipe(
        map((res) => this.caseConversionUtil.toCamelCase(res)),
        catchError((_e) => of([]))
      );
  }

  searchProducts(searchKey: string): Observable<Array<any>> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/products?search=${searchKey}`;
    return this.httpClient.get(url).pipe(
      map((res: { rows: []; count: number }) => res.rows),
      catchError((e) => {
        this.logger.error("[Service failed to fetch on search products]: ", e);
        return of([]);
      })
    );
  }

  getAllPrebuiltSegments(): Observable<IPredefinedAllSegmentsDetail[]> {
    const url = `${environment.segmentServiceBaseURL}/segments/prebuilt`;
    return this.httpClient.get<{ message: string, segments: IPredefinedAllSegmentsDetail[] }>(url).pipe(
      map((res) =>
        res.segments.map((segment) => ({
          ...this.caseConversionUtil.toCamelCase(segment),
        }))
      ),
      catchError((err) => {
        this.logger.error(err);
        return of([]);
      })
    );
  }

  getPrebuiltSegment(segmentId: string): Promise<IPredefinedSegmentDetail> {
    const url = `${environment.segmentServiceBaseURL}/segments/prebuilt/${segmentId}`;
    return this.httpClient.get<{ message: string, segments: IPredefinedSegmentDetail[] }>(url).pipe(
      map((res) => {
        const segments = this.caseConversionUtil.toCamelCase(res.segments);
        return segments[0];
      }),
      catchError((err) => {
        this.logger.error(err);
        return of([]);
      })
    ).toPromise();
  }
}
