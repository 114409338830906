import { IJourneyV2 } from "../../interfaces/journey.interface";
import {
  IDraftJourney,
  IPredefinedJourney,
} from "../../interfaces/journey-v2.interface";

export class AddJourneyList {
  static readonly type = "[Journey] Add list of journey";

  constructor(public payload: Array<IJourneyV2>) {}
}

export class AddPredefinedJourney {
  static readonly type = "[Journey] set predefined journey";
  constructor(public payload: IPredefinedJourney) {}
}

export class AddDraftJourney {
  static readonly type = "[Journey] set draft journey";

  constructor(public payload: IDraftJourney) {}
}
