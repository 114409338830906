import { ContentChild, Directive, Input } from "@angular/core";
import { AccordionContentDirective } from "./accordion-content.directive";
import { AccordionTitleDirective } from "./accordion-title.directive";
import { AccordionHeaderDirective } from "./accordion-header.directive";

@Directive({
  selector: "tf-accordion-item",
})
export class AccordionItemDirective {
  @Input() title = "";
  @Input() disabled = false;
  @Input() expanded = false;
  @ContentChild(AccordionContentDirective) content: AccordionContentDirective;
  @ContentChild(AccordionTitleDirective) customTitle: AccordionTitleDirective;
  @ContentChild(AccordionHeaderDirective)
  customHeader: AccordionHeaderDirective;
}
