import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from "@angular/core";
import { AccordionItemDirective } from "./accordion-item.directive";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { merge, of } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "tf-accordion",
  templateUrl: "./tf-accordion.component.html",
  styleUrls: ["./tf-accordion.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("contentExpansion", [
      state(
        "expanded",
        style({ height: "*", opacity: 1, visibility: "visible" }),
      ),
      state(
        "collapsed",
        style({ height: "0px", opacity: 0, visibility: "hidden" }),
      ),
      transition(
        "expanded <=> collapsed",
        animate("200ms cubic-bezier(.37,1.04,.68,.98)"),
      ),
    ]),
  ],
})
export class TfAccordionComponent {
  @ContentChildren(AccordionItemDirective)
  items: QueryList<AccordionItemDirective>;
  showSection: boolean = false;
  expanded = new Set<number>();
  /**
   * Decides if the single item will be open at once or not.
   * In collapsing mode, toggling one would collapse others
   */
  @Input() collapsing = true;
  @Input() additionalClasses?: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    merge(this.items.changes, of(this.items))
      .pipe(map(() => this.items.toArray()))
      .subscribe((items) => {
        items.forEach((item, index) => {
          if (item.expanded) {
            this.expanded.add(index);
          }
        });
        this.cdr.markForCheck();
      });
  }

  /**
   * Make the toggle function available to be called from
   * outside.
   * @param index - Index of the accordion item
   */
  getToggleState = (index: number) => {
    return this.toggleState.bind(this, index);
  };

  toggleState = (index: number) => {
    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }
  };
}
