import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgxLocalStorageModule } from "ngx-localstorage";
import { HeaderInterceptor } from "./interceptor/http-interceptor";
import { LoaderService } from "./core/services/loader.service";
import { LoaderInterceptor } from "./interceptor/loader.interceptor";
import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { environment } from "../environments/environment";
import { CampaignState } from "./core/store/campaign/campaign.state";
import { ShopifyStoreState } from "./core/store/user-store/user-store.state";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { SegmentState } from "./core/store/segments/segment.state";
import { IdentityState } from "./core/store/identities/identities.state";
import { ErrorCatchingInterceptor } from "./interceptor/error.interceptor";
import { UserStoreState } from "./core/store/user/user.state";
import { SectionAvailabilityStatus } from "./core/store/status/status.state";
import { NgxLoadingModule } from "ngx-loading";
import { GLOBAL_LOADER_CONFIG } from "./core/constants/loader.constant";
import { SharedModule } from "./shared/shared.module";
import { JourneyState } from "./core/store/journey/journey.state";
import { TfModalComponent } from "./components/core/tf-modal/tf-modal.component";
import { TfToastComponent } from "./components/core/tf-toast/tf-toast.component";

@NgModule({
  declarations: [AppComponent, TfModalComponent, TfToastComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    NgxLocalStorageModule.forRoot(),
    NgxLoadingModule.forRoot(GLOBAL_LOADER_CONFIG),
    NgxsModule.forRoot(
      [
        CampaignState,
        ShopifyStoreState,
        SegmentState,
        IdentityState,
        UserStoreState,
        JourneyState,
        SectionAvailabilityStatus,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot(),
    SharedModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
