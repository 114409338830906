import { IPlan } from "../interfaces/subscriptionPlans.interface";

export const SUPPORT_MAIL_ADDRESS = "support@tryforward.io";

export const RATING_CONSTANT = [
  {
    author: { name: "Gitesh Khurana", designation: "Founder, GottaGo.in" },
    userOpinion: `Forward is definitely one of the best Omni channel marketing app 
        on Shopify... may be the best looking at how competitively it is priced. Easy to set up
        and get going + you have awesome support with Venkat, Karthik & rest of the team hand holding you
        all the way.`,
    rate: 5,
  },
];

export const APP_EMBED_LINK =
  "/admin/themes/current/editor?context=apps&activateAppId=22fb4efe-3619-475e-9781-283824d1f608/app-embed";

export const DEFAULT_SUBSCRIPTION_PLAN: IPlan = {
  id: 1,
  subscription_key: "kickoff",
  plan_name: "Kickstart",
  plan_description: "",
  price: 0,
  customer_success: false,
  journey_quota: 0,
  free_credits: 3,
  channels: [
    {
      channel_id: 1,
      channel_name: "Email",
      enabled: true,
    },
    {
      channel_id: 2,
      channel_name: "SMS",
      enabled: false,
    },
    {
      channel_id: 3,
      channel_name: "Push notifications",
      enabled: false,
    },
    {
      channel_id: 4,
      channel_name: "In App Messages",
      enabled: false,
    },
    {
      channel_id: 34,
      channel_name: "WhatsApp",
      enabled: false,
    },
  ],
  rate_card: {
    email_price: 0.0016666667,
    sms_price: 0,
  },
};

export const SUBSCRIPTION_PLAN = {
  KICK_OFF: "kickoff",
  SCALE: "scale",
  FOCUS: "focus",
};

export const BASE_SEGMENT_NAME = "base-segments-all-audience";

export const dummy_ai_segment = {
  segmentName: "Likely to buy next week (Oracle-suggested)",
  description:
    "Customers who have a high likelihood of making a purchase in the next 7 days",
  query: [
    {
      combinator: "and",
      baseSegmentId: [],
      rules: [
        {
          field: "event_type",
          fieldType: "string",
          operator: "=",
          values: ["email_opened"],
          attribute: null,
        },
        {
          field: "frequency",
          fieldType: "frequency",
          operator: "=",
          values: [3],
          attribute: null,
        },
        {
          field: "created_at",
          fieldType: "date",
          operator: "last_x_days",
          values: [30],
          attribute: null,
        },
      ],
      filterType: "events",
    },
    {
      combinator: "and",
      baseSegmentId: [],
      rules: [
        {
          field: "aov",
          fieldType: "number",
          operator: ">",
          values: [3],
          attribute: null,
        },
        {
          field: "last_order_date",
          fieldType: "date",
          operator: "between",
          values: ["2023-11-20T18:30:00.000Z", "2024-01-20T18:30:00.000Z"],
          attribute: null,
        },
      ],
      filterType: "view_customers",
    },
    {
      combinator: "and",
      baseSegmentId: [],
      rules: [
        {
          field: "order_status",
          fieldType: "string",
          operator: "=",
          values: ["placed"],
          attribute: null,
        },
        {
          field: "frequency",
          fieldType: "frequency",
          operator: "=",
          values: [3],
          attribute: null,
        },
        {
          field: "created_at",
          fieldType: "date",
          operator: "last_x_days",
          values: [30],
          attribute: null,
        },
      ],
      filterType: "view_orders",
    },
  ],
  tags: [],
};

export const oracle_suggestions = [
  {
    title: "Customers who have a high likelihood of buying in the next week",
    endpoint: 980,
  },
  {
    title: "Customers who have a high risk of churn",
    endpoint: 752,
  },
  {
    title: "Shoppers who may engage with a discount campaign",
    endpoint: 689,
  },
  {
    title: "New customers who may churn if not engaged with",
    endpoint: 319,
  },
  {
    title: "Shoppers who are likely to drop a review",
    endpoint: 109,
  },
  {
    title: "Customers who show patterns of becoming high-value customers",
    endpoint: 97,
  },
  {
    title: "Customers likely to cancel their subscription",
    endpoint: 39,
  },
];
