export const environment = {
  production: false,
  cognitoClientId: "5tggar7bfp4nrdoeok37sjuiq",
  userPoolId: "us-east-1_mGlK2FuFY",
  cognitoRegion: "us-east-1",
  shopifyRedirectURL: "/shopifyconnect",
  shopifyAppEndpoint:
    "/admin/oauth/authorize?client_id=437d2a8c659302ffcd60ef60b6c2625d&scope=read_orders,read_customers,read_price_rules,read_assigned_fulfillment_orders,read_checkouts,read_content,read_discounts,read_fulfillments,read_inventory,read_products,read_product_listings,read_shipping,read_order_edits,write_customers&redirect_uri=",
  API_getEnrolledStores: "/getEnrolledStores",
  baseServiceURL: "https://api.dev.tryforward.io",
  bucketCloudfront: "https://djvnxpsc9iyvi.cloudfront.net",
  emailBuilderApp:
    "https://tf-email-builder.dev.tryforward.io/assets/remoteEntry.js",
  stripeToken:
    "pk_test_51L68chJjUIV0g7ikZznaLVZIq4NRP0qxoticoI3bvn4jaOBVUaTmA8rpDRvnXQ1bE47u6WkezrzrfDU6moDpILmW00M85ksI7S",
  accountArn: "arn:aws:ses:us-east-1:359346152782:identity",
  domain: "https://dev.tryforward.io",
  disableStoreEndpoint:
    "https://h5bd6sshn4.execute-api.us-east-1.amazonaws.com/dev/admin/disableStore",
  segmentServiceBaseURL:
    "https://h5bd6sshn4.execute-api.us-east-1.amazonaws.com/dev",
  paymentBaseUrl: "https://skci567lsh.execute-api.us-east-1.amazonaws.com/dev",
  dashboardServiceBaseURL:
    "https://3oalokxnod.execute-api.us-east-1.amazonaws.com/dev",
  campaignServiceBaseURL:
    "https://tr05qgnlsh.execute-api.us-east-1.amazonaws.com/dev",
  whatsappPartnerId: "hthcVqPA",
};
