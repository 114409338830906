<div class="tf-accordion-group">
    <div
            class="tf-accordion"
            *ngFor="let item of items; index as i"
            [class.disabled]="item.disabled"
            [class.active]="expanded.has(i)"
    >
        <button
                class="py-3 inline-flex items-center justify-between gap-x-3 w-full
                font-semibold text-start text-gray-800 rounded-lg disabled:opacity-50
                disabled:pointer-events-none {{additionalClasses}}"
                (click)="!item.disabled && getToggleState(i)()"
        >
            <ng-container
                    [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
                    [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            ></ng-container>
            <svg
                    *ngIf="!item.disabled"
                    class="hs-accordion-active:hidden block w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
            >
                <path d="m6 9 6 6 6-6"/>
            </svg>
            <svg
                    *ngIf="!item.disabled"
                    class="hs-accordion-active:block hidden w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
            >
                <path d="m18 15-6-6-6 6"/>
            </svg>
        </button>
        <div
                class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                [class.expanded]="expanded.has(i)"
                [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'"
        >
            <ng-container
                    *ngTemplateOutlet="item?.content?.templateRef"
            ></ng-container>
        </div>
    </div>
</div>
<ng-template #defaultHeader let-item let-index="index">
    <header class="w-full" (click)="item.disabled ? {} : toggleState(index)">
        <ng-container
                *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"
        ></ng-container>
    </header>
    <ng-template #defaultTitle>
        <p class="accordion__title">{{ item?.title }}</p>
    </ng-template>
</ng-template>
