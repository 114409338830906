import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {
  IEnrolledStore,
  IEnrolledStoreService,
  ISetupStore,
  IShopifyStore,
} from "../interfaces/store.interface";
import { Store } from "@ngxs/store";
import { SelectedStore } from "../store/user-store/user-store.action";
import { catchError, map } from "rxjs/operators";
import { CaseConversion } from "../utils/case-conversion.util";
import { Observable, of } from "rxjs";
import { ITutorialStep } from "../../widget/tutorial/tutorial.component";

@Injectable({ providedIn: "root" })
export class StoreService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private caseConversionUtil: CaseConversion
  ) {}

  async isStoreExist(
    storeURL: string
  ): Promise<{ isStoreExists: boolean; message: string }> {
    const url = `${environment.baseServiceURL}/isStoreExists`;
    return this.httpClient
      .post<{ isStoreExists: boolean; message: string }>(url, { storeURL })
      .toPromise();
  }

  async setupStore(setup: ISetupStore) {
    const url = `${environment.baseServiceURL}/airbyte/setup`;
    return this.httpClient.post(url, setup).toPromise();
  }

  async switchStore(storeId: string): Promise<any> {
    const url = `${environment.baseServiceURL}/store/switch`;
    return this.httpClient.post(url, { to: storeId }).toPromise();
  }

  async switchStoreAndUpdateState(storeId: string): Promise<IShopifyStore> {
    try {
      const shopifyStore = await this.switchStore(storeId);
      this.store.dispatch(new SelectedStore(shopifyStore));
      return shopifyStore;
    } catch (error) {}
  }

  disableStore(storeId: string): Promise<any> {
    return this.httpClient
      .post(environment.disableStoreEndpoint, { storeId })
      .toPromise();
  }

  getEnrolledStoresList(search?: string): Observable<Array<IEnrolledStore>> {
    const apiurl = search
      ? `${environment.baseServiceURL}/stores?search=${search}`
      : `${environment.baseServiceURL}/stores`;
    return this.httpClient
      .get<{ rows: Array<IEnrolledStoreService> }>(apiurl)
      .pipe(
        map((store) => this.caseConversionUtil.toCamelCase(store.rows)),
        catchError((_e) => of([]))
      );
  }

  getOnboardingStatusCompletion(): Promise<{
    steps: ITutorialStep[];
    completion: number;
  }> {
    return this.httpClient
      .get<{ steps: ITutorialStep[]; completion: number }>(
        `${environment.baseServiceURL}/customer/onboardingStatus`
      )
      .toPromise();
  }
}
