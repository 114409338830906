<div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
  <div class="flex items-center justify-between pt-3 px-4">
    <h5 class="text-lg font-semibold text-gray-800">Choose Reference</h5>
    <span
      class="p-2 rounded hover:bg-ternary-100 flex items-center justify-center cursor-pointer"
      (click)="close()"
    >
      <i class="bi-x-lg scale-125"></i>
    </span>
  </div>
  <div class="p-4">
    <p class="text-sm mb-4">How did you hear about us?</p>
    <ng-select
      appearance="outline"
      [searchable]="true"
      autofocus
      [formControl]="referenceControl"
    >
      <ng-option value="" disabled>Select platform</ng-option>
      <ng-option
        [value]="reference.id"
        *ngFor="let reference of data"
      >
        {{ reference?.display_name }}
      </ng-option
      >
    </ng-select>
  </div>
  <div class="flex items-center justify-end p-4 pt-0">
    <tf-button
      (onClick)="close('1')"
      name="Set Category"
    />
  </div>
</div>

