export enum StorageKeys {
  STORE_INFO = "storeInfo",
  CUSTOMER_ID = "customerId",
  STORE_PREFIX = "storePrefix",
  CAMPAIGN_TEMP = "campaignTemp",
  IS_WORKER_AVAILABLE = "workerAvailable",
  SUBSCRIPTION_HOLD_JOURNEY_DATA = "subscriptionHoldJourneyData",
}

export const THRESHOLD_AMOUNT = 1;
export const STORE_STATUS_CHECK_INTERVAL = 15; // in seconds


export const SUPPORT_EMAIL_ADDRESS='support@tryforward.io';
