import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
  static campaignDescriptionLength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return (control.dirty || control.touched) && control.value.length > 100
        ? { lengthExceed: true }
        : null;
    };
  }

  static percentValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validity = null;
      if (control.dirty || control.touched) {
        validity =
          control.value >= 0 && control.value <= 100
            ? null
            : { invalidPercentage: true };
      }
      return validity;
    };
  }

  static positiveValue(error: string = "invalidDayOrHour"): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validity = null;
      if (control.dirty || control.touched) {
        validity = control.value > 0 ? null : { [error]: true };
      }
      return validity;
    };
  }

  static validNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validity = null;
      if (control.dirty || control.touched) {
        validity = isNaN(parseFloat(control.value))
          ? { invalidMetricValue: true }
          : null;
      }
      return validity;
    };
  }

  static validPrice(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validity = null;
      if (control.dirty || control.touched) {
        validity = control.value > 10000 ? { maxAmountExceed: true } : null;
      }
      return validity;
    };
  }

  static passwordMatch(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const formData = form.value;
      if (
        form.get("password").touched &&
        form.get("confirmPassword").touched &&
        formData.password !== formData.confirmPassword
      )
        return { passwordMisMatch: true };
    };
  }

  static validateDomain(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const isValidDomain = control.value.match(
          /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/g
        );
        let validDomain = false;
        if (isValidDomain && isValidDomain.length > 0) {
          validDomain = control.value.length === isValidDomain[0].length;
        }
        if (!validDomain) {
          return { invalidDomain: true };
        }
      }
    };
  }

  static validAmountOfTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValidAmountOfTime = control.value % 1 === 0;
      return isValidAmountOfTime ? null : { invalidTime: true };
    };
  }
}
