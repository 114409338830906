import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  IChannel,
  IEmailChannel,
  IWhatsappOnboard,
} from "../interfaces/channel.interface";
import { CommonUtilService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class ChannelService {
  constructor(
    private httpClient: HttpClient,
    private commonUtilService: CommonUtilService
  ) {}

  getAllChannels(): Promise<Array<IChannel>> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/channels`;
    return this.httpClient.get<Array<IChannel>>(url).toPromise();
  }

  getChannel(channelType: string): Promise<IChannel | IEmailChannel> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/channels?channelType=${channelType}`;
    return this.httpClient.get<IChannel | IEmailChannel>(url).toPromise();
  }

  updateEmailChannel(updateRequest: any): Promise<IChannel> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/channels/email`;
    return this.httpClient.patch<IChannel>(url, updateRequest).toPromise();
  }

  updateSmsChannel(updateRequest: any): Promise<IChannel> {
    const url = `${
      environment.baseServiceURL
    }/customer/${this.commonUtilService.getActiveCustomerId()}/channels/sms`;
    return this.httpClient.patch<IChannel>(url, updateRequest).toPromise();
  }

  enableWhatsapp(data: IWhatsappOnboard) {
    const url = `${environment.segmentServiceBaseURL}/whatsapp/interakt/onboard`;
    return this.httpClient.post(url, data).toPromise();
  }
}
