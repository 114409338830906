import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Select } from "@ngxs/store";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UserStoreState } from "../store/user/user.state";
import { NGXLogger } from "ngx-logger";
import { IUser } from "../interfaces/user.interface";

@Injectable({ providedIn: "root" })
export class AuthGuard  {
  @Select(UserStoreState.getCurrentUser)
  currentUser$: Observable<IUser>;

  constructor(private router: Router, private logger: NGXLogger) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.logger.log("[auth-guard] 1: ", new Date().toISOString());
    return combineLatest([this.currentUser$]).pipe(
      map(([currentUser]) => {
        this.logger.log("[auth-guard]", currentUser);
        if (!currentUser) {
          this.router
            .navigate(["/account/login"], {
              queryParams: { returnUrl: state.url },
            })
            .then();
        }
        return !!currentUser;
      })
    );
  }
}
