import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { Auth } from "aws-amplify";
import { switchMap } from "rxjs/operators";
import { LocalStorageService } from "ngx-localstorage";
import { StorageKeys } from "../core/constants/storageKeys.constant";
import { CommonUtilService } from "../core/services/common.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  static token: string = "";
  constructor(
    private storageService: LocalStorageService,
    private commonUtilService: CommonUtilService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes("/unsubscribe")) {
      return next.handle(req);
    }
    return from(this.getAuthToken()).pipe(
      switchMap((token: string) => {
        let headers = req.headers.set("Authorization", `Bearer ${token}`);
        const customerId = this.storageService.get(StorageKeys.CUSTOMER_ID);
        if (!!customerId && customerId !== "") {
          headers = headers
            .set("X-StoreId", this.storageService.get(StorageKeys.CUSTOMER_ID))
            .set("X-Prefix", this.storageService.get(StorageKeys.STORE_PREFIX));
        } else if (
          ![
            "getEnrolledStores",
            "isStoreExists",
            "stores",
            "/store/switch",
          ].some((str) => req.url.includes(str))
        ) {
          this.commonUtilService.isValidCustomerId(customerId);
          return;
        }
        const authRequest = req.clone({ headers });
        return next.handle(authRequest);
      })
    );
  }

  async getAuthToken(): Promise<string> {
    if (!HeaderInterceptor.token) {
      HeaderInterceptor.token = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
    }
    return HeaderInterceptor.token;
  }
}
