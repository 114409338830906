import { Component, Input } from "@angular/core";
import { IFile } from "../../core/interfaces/folder.interface";
import { ModalService } from "../../core/services/modal.service";

@Component({
  selector: "tf-preview",
  templateUrl: "./preview.component.html",
})
export class PreviewComponent {
  @Input() file: IFile;

  constructor(private activeModal: ModalService) {}

  public close(): void {
    this.activeModal.close();
  }
}
