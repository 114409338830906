import { Component, Input, OnInit } from "@angular/core";
import { IBreadcrumbItem } from "../../../core/interfaces/core-elements.interface";

@Component({
  selector: "tf-breadcrumb",
  templateUrl: "./tf-breadcrumb.component.html",
  styleUrls: ["./tf-breadcrumb.component.scss"],
})
export class TfBreadcrumbComponent implements OnInit {
  @Input() breadcrumbs: Array<IBreadcrumbItem> = [];
  @Input() currentTabName!: string;

  constructor() {}

  ngOnInit(): void {}
}
