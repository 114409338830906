import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { IStaticData } from "../interfaces/store.interface";
import { CommonUtilService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class StaticService {
  constructor(
    private httpClient: HttpClient,
    private commonUtilService: CommonUtilService
  ) {}

  getStaticData() {
    const url = `${environment.baseServiceURL}/static?include=channels,categories,goals,references`;
    return this.httpClient.get<IStaticData>(url).toPromise();
  }
}
