<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        {{ year }} © Forward Apps Inc |
        <a
          href="https://go.crisp.chat/chat/embed/?website_id=e3f01171-ec20-49e4-9c5a-67221f39a094"
          target="_blank"
        >Need Help?</a
        >
      </div>
    </div>
  </div>
</footer>
